import React from 'react';
import { Card, Col, Row, Grid } from 'antd';
import { Link } from 'react-router-dom';
import { Banneritem, navData } from '../../constant/data';

const { useBreakpoint } = Grid;

const Cateogories = () => {
    const screens = useBreakpoint();

    return (
        <Card >
            <div className="site-card-wrapper">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {Banneritem.map((category, index) => (
                        <Col className="gutter-row" xs={24} sm={12} md={8} lg={8} xl={8} key={category.id}>
                            <Card
                                hoverable
                                style={{ marginTop: "20px" }}
                                styles={{ body: { padding: '0px' } }}
                                cover={<img alt={category.image} src={category.url} />}
                            >
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </Card>
    );
}

export default Cateogories;