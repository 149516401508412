import { useState, useEffect } from 'react';
import { styled, Box, Typography, Grid, CircularProgress, Button, useMediaQuery } from '@mui/material';
import { notification, Rate, Tag } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProductDetails } from '../../redux/actions/productActions';
import axiosInstance from '../../AxiosInsstance';
import Slide from '../Home/Slide';
import { useAuth0 } from '@auth0/auth0-react';

const Component = styled(Box)(({ theme }) => ({
    marginTop: '55px',
    background: '#F2F2F2',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
        padding: '15px',
    },
    [theme.breakpoints.down('xs')]: {
        padding: '10px',
    },
}));

const Container = styled(Grid)(({ theme }) => ({
    background: '#FFFFFF',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
        padding: '20px',
    },
}));

const LeftContainer = styled(Grid)({
    padding: '10px',
});

const RightContainer = styled(Grid)(({ theme }) => ({
    padding: '0 20px',
    [theme.breakpoints.down('md')]: {
        marginTop: '30px',
        padding: '0',
    },
}));

const LongText = styled(Typography)(({ theme }) => ({
    fontSize: '26px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '10px',
    [theme.breakpoints.down('md')]: {
        fontSize: '22px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
    },
}));

const PriceTag = styled(Tag)(({ theme }) => ({
    color: '#FFF',
    backgroundColor: '#AD1E24',
    fontSize: '24px',
    fontWeight: 'bold',
    padding: '8px 16px',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        padding: '6px 12px',
    },
}));

const MainImageContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '15px',
    borderRadius: '8px',
    overflow: 'hidden',
});

const MainImage = styled('img')({
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
});

const ThumbnailContainer = styled(Box)({
    display: 'flex',
    gap: '10px',
    overflowX: 'auto',
    marginTop: '10px',
    paddingBottom: '10px',
    '&::-webkit-scrollbar': { display: 'none' },
});

const Thumbnail = styled('img')({
    width: '70px',
    height: '70px',
    borderRadius: '5px',
    cursor: 'pointer',
    border: '2px solid transparent',
    transition: 'border 0.2s ease',
    '&:hover': {
        border: `2px solid #AD1E24`,
    },
});
const DetailView = () => {
    const { id } = useParams();
    const { products } = useSelector(state => state.getProducts);
    const { loading, product } = useSelector(state => state.getProductDetails);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const isMdUp = useMediaQuery(theme => theme.breakpoints.down(960));

    const [mainImage, setMainImage] = useState('');
    const [reviews, setReviews] = useState([]);

    // Fetch reviews if product is available
    useEffect(() => {
        const fetchReviews = async () => {
            if (product?.id) {
            try {
                    const response = await axiosInstance.get(`/reviews/${product.id}`);
                setReviews(response.data);
            } catch (error) {
                console.error('Failed to fetch reviews', error);
                }
            }
        };
        fetchReviews();
    }, [product?.id]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (id && (!product || id !== product.id)) {
            dispatch(getProductDetails(id));
        }
    }, [dispatch, product, id]);

    useEffect(() => {
        if (product?.url && product.url.length > 0) {
            setMainImage(product.url[0]);
        }
    }, [product]);

    const handleNextStep = () => {
        const user_name = sessionStorage.getItem('user_name');
        if (product?.productby !== user_name) {
            navigate(`/rental-pay/${id}`);
        } else {
            notification.info({
                message: "Warning",
                description: "You cannot rent your own product.",
            });
        }
    };

    const handleClick = () => {
        if (!isAuthenticated) {
            loginWithRedirect();
        } else {
            handleNextStep();
        }
    };

    if (loading || !product) {
        // Show loading animation if data is still being fetched
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh", width: "100vw" }}>
                <CircularProgress />
            </div>
        );
    }

    // Handle when product or other important data is missing
    if (!product || !product.url || !product.times || !product.rules) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh", width: "100vw" }}>
                <Typography variant="h6" color="error">
                    Product details are unavailable.
                </Typography>
            </div>
        );
    }

    // Filter similar products based on category if product exists
    const SimilarProducts = product ? products.filter(item => item?.category === product?.category) : [];

    return (
        <Component>
            {product && (
                <Container container spacing={4}>
                    {/* Left Container - Image and Rating */}
                    <LeftContainer item lg={5} md={6} xs={12}>
                        <MainImageContainer>
                            <MainImage src={mainImage || ''} alt="Product" />
                        </MainImageContainer>
                        <ThumbnailContainer>
                            {product.url && product.url.map((imgUrl, index) => (
                                <Thumbnail
                                    key={index}
                                    src={imgUrl}
                                    alt={`Thumbnail ${index + 1}`}
                                    onClick={() => setMainImage(imgUrl)}
                                    style={{ border: mainImage === imgUrl ? '2px solid #AD1E24' : '' }}
                                />
                            ))}
                        </ThumbnailContainer>
                        <Rate disabled allowHalf defaultValue={product?.averageRating || 0} style={{ fontSize: '20px', color: '#AD1E24' }} />
                        <Typography style={{ marginTop: 8, color: '#878787', fontSize: '16px' }}>
                            {product?.averageRating ? product.averageRating.toFixed(1) : 0} ({reviews.length} Reviews)
                        </Typography>
                    </LeftContainer>

                    {/* Right Container - Product Details */}
                    <RightContainer item lg={7} md={6} xs={12}>
                        <LongText>{product?.title?.longTitle || 'No title available'}</LongText>
                        <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: '15px' }}>
                            {product?.tagline || 'No tagline available'} | Category: {product?.category || 'Unknown'}
                        </Typography>
                        <PriceTag>${(product?.price * 24).toFixed(2)} / day</PriceTag>

                        {/* Availability */}
                        <Typography variant="h6" style={{ marginTop: '15px', fontWeight: 'bold' }}>Availability Pickup Times:</Typography>
                        <Box display="flex" flexWrap="wrap" mt={1}>
                            {product?.times?.map((time, index) => (
                                <Tag key={index} color="default" style={{ marginRight: '8px', fontSize: '16px' }}>
                                    {time}
                                </Tag>
                            ))}
                        </Box>

                        {/* Location */}
                        <Typography variant="h6" style={{ marginTop: '15px', fontWeight: 'bold' }}>Location:</Typography>
                        <Typography variant="body1">{product?.address || 'No location provided'}</Typography>

                        {/* Description */}
                        <Typography variant="h6" style={{ marginTop: '15px', fontWeight: 'bold' }}>Description:</Typography>
                        <Typography variant="body2" style={{ color: '#555' }}>{product.description}</Typography>

                        {/* Rental Rules */}
                        <Typography variant="h6" style={{ marginTop: '15px', fontWeight: 'bold' }}>Rules:</Typography>
                        <Box display="flex" flexWrap="wrap" mt={1}>
                            {product?.rules && product.rules.map((rule, index) => (
                                <Tag color="red" key={index} style={{ margin: '4px' }}>{rule}</Tag>
                            ))}
                        </Box>

                        {/* Action Buttons */}
                        <Box mt={3} display="flex" gap={2}>
                            <Button variant="contained" style={{ backgroundColor: '#AD1E24', color: '#FFF', fontWeight: 'bold' }} onClick={handleClick}>
                                Rent Now
                            </Button>
                            <Button variant="outlined" style={{ color: '#AD1E24', borderColor: '#AD1E24' }}>
                                Add to Wishlist
                            </Button>
                        </Box>
                    </RightContainer>
                </Container>
            )}
        </Component>
    );
};

export default DetailView;
