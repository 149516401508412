import React from 'react';
import { bannerData } from '../../constant/data';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Banner.css'; // Create this CSS file in the same directory

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  }
};

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-wrapper">
        <Carousel
          swipeable={false}
          draggable={false}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          showDots={false}
          slidesToSlide={1}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {bannerData.map(image => (
            <div
              key={image.id}
              className="banner-slide"
            >
              <div
                className="banner-image"
                
                style={{
                  backgroundImage: `url(${image.url})`
                  
                }}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Banner;