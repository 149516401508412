export const GET_PRODUCTS_REQUEST = 'getProductsRequest'
export const GET_PRODUCTS_SUCCESS = 'getProductsSuccess'
export const GET_PRODUCTS_FAIL = 'getProductsFail'


export const GET_PRODUCT_DETAILS_REQUEST = 'getProductDetailsRequest'
export const GET_PRODUCT_DETAILS_SUCCESS = 'getProductDetailSuccess'
export const GET_PRODUCT_DETAILS_FAIL = 'getProductDetailFail'
export const GET_PRODUCT_DETAILS_RESET = 'getProductDetailReset'

export const CHANGE_PRODUCT_STATUS_REQUEST = 'CHANGE_PRODUCT_STATUS_REQUEST';
export const CHANGE_PRODUCT_STATUS_SUCCESS = 'CHANGE_PRODUCT_STATUS_SUCCESS';
export const CHANGE_PRODUCT_STATUS_FAIL = 'CHANGE_PRODUCT_STATUS_FAIL';

export const SET_CATEGORY_REQUEST = 'SET_CATEGORY_REQUEST';
export const SET_CATEGORY_SUCCESS = 'SET_CATEGORY_SUCCESS';
export const SET_CATEGORY_FAIL = 'SET_CATEGORY_FAIL';

export const SET_LOCATION_REQUEST = 'SET_LOCATION_REQUEST';
export const SET_LOCATION_SUCCESS = 'SET_LOCATION_SUCCESS';
export const SET_LOCATION_FAIL = 'SET_LOCATION_FAIL';


export const LOAD_MORE_PRODUCTS_REQUEST = 'LOAD_MORE_PRODUCTS_REQUEST';
export const LOAD_MORE_PRODUCTS_SUCCESS = 'LOAD_MORE_PRODUCTS_SUCCESS';
export const LOAD_MORE_PRODUCTS_FAIL = 'LOAD_MORE_PRODUCTS_FAIL';

export const RESET_PRODUCTS = 'RESET_PRODUCTS';

export const RESET_LOCATION = 'RESET_LOCATION';