import { useState, useEffect, useRef, useCallback } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase, List, ListItem, Box, styled, Divider, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setCategoryState } from '../../redux/actions/productActions';
import { Link, useNavigate } from 'react-router-dom';
import * as colors from '../Color/color';
import { navData } from '../../constant/data';
import { Select as AntdSelect } from 'antd';
import './search.css';
import { setSearchModalDrawer } from '../../redux/searchModalDrawer';
import { BASE_URL } from '../../constants';

const { Option } = AntdSelect;

const SearchContainer = styled(Box)`
  border-radius: 10px;
  margin-left: 2%;
  margin-right: 2%;
  width: 95%;
  background-color: #fff;
  display: flex;
  position: relative;
  height: 40px;
  align-items: center;
  padding: 0px !important; 
  border: 1px solid rgba(173, 30, 36, 0.2);
  box-shadow: 0 2px 20px rgba(173, 30, 36, 0.2);
  @media (max-width: 600px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const SearchIconWrapper = styled(Box)`
  margin-left: auto;
  padding: 5px;
  display: flex;
  color: ${colors.main_theme_color};
`;

const ListWrapper = styled(List)`
  position: absolute;
  color: #000;
  background: #FFFFFF;
  top: 45px;
  width: 100%;
  z-index: 1000;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
`;

const HighlightedListItem = styled(ListItem)`
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: ${({ highlighted }) => (highlighted ? '#f0f0f0' : 'transparent')};
  &:hover {
    background-color: #f0f0f0;
  }
`;

const InputSearchBase = styled(InputBase)`
  font-size: unset;
  width: 100%;
  padding-left: 20px;
`;

const Search = () => {
  const [text, setText] = useState('');
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const navigate = useNavigate();
  const listRef = useRef(null);
  const [category, setCategory] = useState('');
  const isMdUp = useMediaQuery('(min-width:960px)');
  const { searchModalDrawer } = useSelector(state => state.searchModalDrawer);
  const dispatch = useDispatch();
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const res = await fetch(`${BASE_URL}/search?q=${text}`);
        const data = await res.json();
        if (res.ok) {
          setFilteredProducts(data);
        } else {
          console.error('Failed to fetch search results:', data.error);
          setFilteredProducts([]);
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
        setFilteredProducts([]);
      }
    };

    fetchSearchResults();
  }, [text]);

  const handleCategoryChange = (event) => {
    setCategory(event);
    dispatch(setCategoryState(event));
  };

  const getText = (text) => {
    setText(text);
    setOpen(false);
    setSelectedIndex(-1);
  };

  const handleKeyDown = useCallback((e) => {
    if (!text) return;

    if (e.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) => {
        const nextIndex = prevIndex < filteredProducts.length - 1 ? prevIndex + 1 : prevIndex;
        scrollToItem(nextIndex);
        return nextIndex;
      });
    } else if (e.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) => {
        const nextIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToItem(nextIndex);
        return nextIndex;
      });
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      navigate(`/product/${filteredProducts[selectedIndex].id}`);
      setOpen(true);
    }
  }, [selectedIndex, filteredProducts, navigate, text]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const scrollToItem = (index) => {
    const list = listRef.current;
    const item = list?.children[index];
    if (item) {
      item.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  return (
    <SearchContainer>
      <InputSearchBase
        style={{ height: "30px" }}
        placeholder="Search for products, brands and more"
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => getText(e.target.value)}
        startAdornment={
          <Box display="flex" alignItems="center">
            {isMdUp && (
              <>
                <Box display="flex" justifyContent="center">
                  <AntdSelect
                    id="category-select"
                    value={category}
                    onChange={handleCategoryChange}
                    variant="borderless"
                    style={{
                      marginRight: "10px",
                      fontFamily: "sans-serif",
                      zIndex: 9999,
                      minWidth: "40px"
                    }}
                  >
                    <Option value="">
                      All Categories
                    </Option>
                    {navData.map((item, index) => (
                      <Option key={index} value={item.text}>
                        {item.text}
                      </Option>
                    ))}
                  </AntdSelect>
                </Box>
                <Divider orientation="vertical" sx={{ height: "36px", marginRight: "30px" }} flexItem />
              </>
            )}
          </Box>
        }
      />
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      {
        text &&
        <ListWrapper ref={listRef} hidden={open}>
          {
            filteredProducts.map((product, index) => (
              <HighlightedListItem
                key={product.id}
                highlighted={index === selectedIndex}
              >
                <Link
                  to={`/product/${product.id}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  onClick={() => setOpen(true)}
                >
                  <div className="container">
                    <div>
                      <img src={`${product.url[0]}`} alt="" />
                    </div>
                    <div className="text">
                      {product.title.shortTitle}
                    </div>
                  </div>
                </Link>
              </HighlightedListItem>
            ))
          }
        </ListWrapper>
      }
    </SearchContainer>
  );
};

export default Search;