import { Box } from '@mui/material';
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
} from 'react-map-gl';
import { useState, useEffect, useRef } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import Geocoder from './Geocoder';

const AddLocation = ({setLocation, location}) => {
  
  const mapRef = useRef();

  const handleMapLoad = () => {
    if (!location.lng && !location.lat) {
      fetch('https://ipapi.co/json')
        .then((response) => response.json())
        .then((data) => {
          if (mapRef.current) {
            mapRef.current.flyTo({
              center: [data.longitude, data.latitude],
            });
            setLocation({
              lng: data.longitude,
              lat: data.latitude,
              town: data.city || '',
              country: data.country_name || '',
              state: data.region || '',
              address: `${data.city}, ${data.region}, ${data.country_name}`,
              postalcode: data.postal || '',
            });
          }
        });
    }
  };

  const fetchAddress = async (lng, lat) => {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=pk.eyJ1IjoiYmFubnU3NzMiLCJhIjoiY20wZmdsejlqMHo2bjJyc2RoY245MXB5aSJ9.9bW8b_fkzFNc8HiuOcYPjw`
    );
    const data = await response.json();
  
    console.log(data, "dataaa");
    const place = data.features.find((feature) =>
      feature.place_type.includes('place')
    );
    const country = data.features.find((feature) =>
      feature.place_type.includes('country')
    );
    const region = data.features.find((feature) =>
      feature.place_type.includes('region')
    );
    const address = data.features.find((feature) =>
      feature.place_type.includes('address')
    );
    const postalcode = data.features.find((feature) =>
      feature.place_type.includes('postcode')
    );
  
    setLocation((prev) => ({
      ...prev,
      country: country ? country.text : '',
      town: place ? place.text : '',
      state: region ? region.text : '',
      address: address ? address.text : '',
      postalcode: postalcode ? postalcode.text : '',
    }));
  };

  useEffect(() => {
    handleMapLoad();
  }, [location.lng, location.lat]);

  return (
    <Box
      sx={{
        height: 400,
        position: 'relative',
      }}
    >
      <ReactMapGL
        ref={mapRef}
        mapboxAccessToken={"pk.eyJ1IjoiYmFubnU3NzMiLCJhIjoiY20wZmdsejlqMHo2bjJyc2RoY245MXB5aSJ9.9bW8b_fkzFNc8HiuOcYPjw"}
        initialViewState={{
          longitude: location.lng || 0,
          latitude: location.lat || 0,
          zoom: 8,
        }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onLoad={handleMapLoad}
        onViewportChange={(newViewport) => {
          setLocation((prev) => ({
            ...prev,
            lng: newViewport.longitude,
            lat: newViewport.latitude,
          }));
        }}
      >
        {location.lng && location.lat && (
          <Marker
            latitude={location.lat}
            longitude={location.lng}
            draggable
            onDragEnd={(e) =>
              setLocation((prev) => ({
                ...prev,
                lng: e.lngLat.lng,
                lat: e.lngLat.lat,
              }))
            }
          />
        )}
        <NavigationControl position="bottom-right" />
        <GeolocateControl
          position="top-left"
          trackUserLocation
          onGeolocate={(e) => {
            if (e.coords) {
              const { longitude, latitude } = e.coords;
              setLocation((prev) => ({
                ...prev,
                lng: e.coords.longitude,
                lat: e.coords.latitude,
              }));
              if (mapRef.current) {
                mapRef.current.flyTo({
                  center: [e.coords.longitude, e.coords.latitude],
                });
              }
              fetchAddress(longitude, latitude);
            }
          }}
          showUserLocation={true}
        />
        <Geocoder setLocation={setLocation} />
      </ReactMapGL>
    </Box>
  );
};

export default AddLocation;
