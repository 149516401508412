import React, { useEffect, useState } from 'react';
import { Typography, Box, InputLabel, Paper, styled, useMediaQuery } from '@mui/material';
import { addProduct } from '../../service/api';
import { navData, times } from '../../constant/data'

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth0 } from '@auth0/auth0-react';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch } from 'react-redux';
import "react-country-state-city/dist/react-country-state-city.css";
import { Form,  Input, Upload, Button, Row, Col, Card, Select, Cascader, Spin, notification, Tag, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DatePicker } from 'antd';
import {
    GetCountries,
    GetState,
    GetCity
} from "react-country-state-city";
import { BASE_URL } from '../../constants';
import { useNavigate } from 'react-router-dom';
import AddLocation from '../../Map/AddLocation';
import dayjs from 'dayjs';
import { setShowVerifyUserLogin } from '../../redux/verifyUserLogin';
import { ClockLoader } from 'react-spinners';
const { Option } = Select;


dayjs.extend(utc);
dayjs.extend(timezone);

const BackButtonWrapper = styled('div')({
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: '#f0f0f0',
    },
});



const AddProduct = () => {
    const [key, setKey] = useState(0);
    const categories = navData.map(item => item.text);
    const [startDateTime, setStartDateTime] = useState(null);   
    const [endDateTime, setEndDateTime] = useState(null);
    const [showRulesInput, setShowRulesInput] = useState(false);
    const [rule, setRule] = useState('');
    const { user } = useAuth0();
    const dispatch = useDispatch();
    const [selectedTimes, setSelectedTimes] = useState([]);
    const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [location, setLocation] = useState({
        lng: 0,
        lat: 0,
        town: '',
        country: '',
        state: '',
        address: '',
        postalcode: ''
    });
    const [product, setProduct] = useState({
        title: {
            shortTitle: '',
            longTitle: ''
        },
        category: '',
        price: '',
        quantity: '',
        description: '',
        tagline: '',
        images: [],
        
        address: '',
        country: '',
        state: '',
        rules: [],
        postalcode: ''
    });
    const [locationOptions, setLocationOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useNavigate();
    const handleBackClick = () => {
        history('/');
    };


    const handleTimeClick = (time) => {
        setSelectedTimes((prevSelectedTimes) => {
            if (prevSelectedTimes.includes(time)) {
                return prevSelectedTimes.filter((t) => t !== time);
            } else {
                return [...prevSelectedTimes, time];
            }
        });
    };

    const handleAddRule = () => {
        setShowRulesInput(true);
    };

    const handleRuleChange = (event) => {
        setRule(event.target.value);
    };

    const handleRuleSubmit = () => {
        setProduct(prevState => ({
            ...prevState,
            rules: [...prevState.rules, rule]
        }));
        setRule('');
        setShowRulesInput(false);
    };

    const handleChange = (name, value) => {
        if (name === "shortTitle" || name === "longTitle") {
            setProduct(prevState => ({
                ...prevState,
                title: {
                    ...prevState.title,
                    [name]: value
                }
            }));
        } else if (name === "quantity" && value < 0) {
            toast.error('Quantity cannot be negative');
        } else if(name === "address" ) {
            setLocation(prevState => ({
                ...prevState,
                address: value
            }));
        } 
        else if(name === "postalcode") {
            setLocation(prevState => ({
                ...prevState,
                postalcode: value
            }));
        }
    
        else {
            setProduct(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleImageChange = ({ fileList }) => {
        setProduct(prevState => ({
            ...prevState,
            images: fileList.map(file => file.originFileObj)
        }));
    };
    const handleSubmit = async () => {
        if (!user || !user.email) {
    
            notification.error({
                message: 'Error',
                description: 'User information is missing. Please verify your details.',
            });
            return;
        }

        try {
            const checkuser = await fetch(`${BASE_URL}/user/${user.email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (checkuser.status === 404) {
                dispatch(  setShowVerifyUserLogin(true));
               message.error('Please verify your email to add product');
                return;
            }

            setLoading(true);

            const formData = new FormData();
            formData.append("id", `product-${Date.now()}`);
            formData.append('shortTitle', product.title.shortTitle);
            formData.append('longTitle', product.title.longTitle);
            formData.append('category', product?.category?.toLowerCase());
            formData.append('price', product.price === 0 ? 0 : product.price / 24);
            formData.append('quantity', product.quantity);
            formData.append('description', product.description);
            product.rules.forEach((rule) => {
                formData.append('rules', rule);
            });
            formData.append('startdatetime', dayjs(startDateTime).toISOString());   
            formData.append('enddatetime', dayjs(endDateTime).toISOString());
            formData.append('tagline', product.tagline);
            formData.append('location', location.town);
            formData.append('address', location.address);
            formData.append("status", "review");
            formData.append("liststatus", "published");
            formData.append("email", user.email);
            formData.append("country", location.country);
            formData.append("state", location.state);
            formData.append("productby", user.email);
            formData.append("longitute", location.lng);
            formData.append("latitude" , location.lat);
            formData.append("postalcode", product.postalcode);
            formData.append("productmodifiedtime", new Date().toISOString())

            product.images.forEach((image) => {
                formData.append('images', image);
            });

            selectedTimes.forEach((time) => {
                formData.append('times', time);
            });

            let response = await addProduct(formData);

            if (response.status === 201) {
                notification.success({
                    message: 'Success',
                    description: 'Product has been successfully added. It\'s currently under review.',
                });
                setProduct({
                    title: {
                        shortTitle: '',
                        longTitle: ''
                    },
                    category: '',
                    price: '',
                    quantity: '',
                    description: '',
                    tagline: '',
                    images: [],
                    location: '',
                    address: '',
                    rules: [],
                    postalcode: ''
                });
                setStartDateTime(null);
                setEndDateTime(null);
                setKey(prevKey => prevKey + 1);
                setLocationOptions([]);
                form.resetFields();
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Failed to add product. Please try again later.',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An unexpected error occurred. Please try again later.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
                <ClockLoader  speedMultiplier={3} size={75} />
                <h4>Loading...</h4>
            </div>
        ) : (
            <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3, py: 5, }}>
                <Paper sx={{ maxWidth: 1200, margin: 'auto', overflow: 'hidden', p: 2 ,marginTop : isMdUp ? "0px" : "50px" }}>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <BackButtonWrapper onClick={handleBackClick}>
                            <ArrowLeftOutlined />
                        </BackButtonWrapper>
                        <div>
                            <Typography variant="p" gutterBottom component="div" sx={{ fontSize: "10px", color: "grey" }}>
                                Back to Home
                            </Typography>
                            <Typography variant="p" gutterBottom component="div" sx={{ fontSize: "14px", fontWeight: "600" }}>
                                Add New Product
                            </Typography>
                        </div>
                    </div>
                    <Form form={form} onFinish={handleSubmit} layout='vertical' style={{ marginTop: "20px" }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12}>
                                <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px" }}>
                                    Description
                                </Typography>
                                <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable >

                                    <Col xs={24} sm={24} md={24}>
                                        <Form.Item
                                            name="shortTitle"
                                            label={
                                                <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Short Name</span>
                                            }
                                            rules={[{ message: 'Please input the short title!' }]}
                                        >
                                            <Input
                                                value={product.title.shortTitle}
                                                onChange={(e) => handleChange('shortTitle', e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24}>
                                        <Form.Item
                                            name="longTitle"
                                            label={
                                                <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Long Name</span>
                                            }
                                            rules={[{ message: 'Please input the long title!' }]}
                                        >
                                            <Input
                                                value={product.title.longTitle}
                                                onChange={(e) => handleChange('longTitle', e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>

                                        <Form.Item
                                            name="description"
                                            label={
                                                <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Description</span>
                                            }
                                        >
                                            <TextArea
                                                rows={8}
                                                value={product.description}
                                                onChange={(e) => handleChange('description', e.target.value)}
                                            />
                                        </Form.Item>

                                    </Col>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px" }}>
                                    Product Images
                                </Typography>
                                <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable >
                                    <Col xs={24}>

                                        <Form.Item
                                            name="images"
                                            label={
                                                <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Images</span>
                                            }
                                            valuePropName="fileList"
                                            getValueFromEvent={(e) => e.fileList}
                                        >
                                            <Upload
                                                listType="picture-card"
                                                beforeUpload={() => false}
                                                onChange={handleImageChange}
                                            >
                                                <div>
                                                    <PlusOutlined />
                                                    <div style={{ marginTop: 8 }}>Upload</div>
                                                </div>
                                            </Upload>
                                        </Form.Item>

                                    </Col>
                                </Card>

                                <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px", marginTop: "20px" }}>
                                    Category
                                </Typography>
                                <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable>
                                    <Col xs={24} sm={24} md={24}>
                                        <div>
                                            <InputLabel style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginBottom: "10px" }}>Category</InputLabel>
                                            <Select
                                                value={product.category}
                                                onChange={(value) => handleChange('category', value)}
                                                name="category"
                                                style={{ width: '100%' }}
                                            >
                                                <Option value="" disabled>
                                                    Select Category
                                                </Option>
                                                {categories.map((category) => (
                                                    <Option key={category} value={category}>{category}</Option>
                                                ))}
                                            </Select>
                                        </div>

                                    </Col>
                                    <Col xs={24} sm={24} md={24}>
                                        <Form.Item
                                            name="tagline"
                                            label={
                                                <span style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginTop: "10px" }}>Taglines</span>
                                            }
                                        >
                                            <Input
                                                value={product.tagline}
                                                onChange={(e) => handleChange('tagline', e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Card>

                            </Col>

                            <Col xs={24} sm={24} md={12}>
                                <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px" }}>
                                    Shipping and Delivery
                                </Typography>
                                <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable>
                                    <Col xs={24} sm={24} md={24}>
                                        <AddLocation setLocation={setLocation} location={location} />
                                    </Col>

                                    <Col xs={24} sm={24} md={24} style={{ marginTop: "20px" }}>
                                        <InputLabel style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginBottom: "10px" }}>Country : {location.country}</InputLabel>
                                        <InputLabel style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginBottom: "10px" }}>State : {location.state}</InputLabel>
                                        <InputLabel style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginBottom: "10px" }}>City : {location.town}</InputLabel>
                                        
                                        <InputLabel style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginBottom: "10px" }}>Address:</InputLabel>
                                        <TextArea
                                            value={location.address}
                                            style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}
                                            onChange={(e) => handleChange('address', e.target.value)}
                                        />
                                        <InputLabel style={{ color: "grey", fontWeight: "500", fontSize: "12px", marginBottom: "10px" }}>Postal Code:</InputLabel>
                                        <Input value={location.postalcode} onChange={(e) => handleChange("postalcode" , e.target.value)  }  />
                                        {/* <Cascader
                                            key={key}
                                            style={{ width: '100%' }}
                                            className="my-cascader"
                                            options={locationOptions}
                                            loadData={loadData}
                                            onChange={handleLocationChange}
                                            placeholder="Location"
                                        /> */}
                                    </Col>
                                    {/* <Col xs={24} sm={24} md={24}>
                                        <Form.Item
                                            name="address"
                                            label={
                                                <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Address</span>
                                            }
                                            style={{ marginTop: "10px" }}
                                        >
                                            <Input
                                                value={product.address}
                                                onChange={(e) => handleChange('address', e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col> */}


                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px" }}>
                                    Pricing
                                </Typography>
                                <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable>

                                    <Col xs={24} sm={24} md={24}>
                                        <Form.Item
                                            name="price"
                                            label={
                                                <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Price/day</span>
                                            }
                                            rules={[{ message: 'Please input the price!' }]}
                                        >
                                            <Input
                                                type="number"
                                                value={product.price}
                                                onChange={(e) => handleChange('price', e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24}>
                                        <Form.Item
                                            name="quantity"
                                            label={
                                                <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Quantity</span>
                                            }
                                            rules={[{ message: 'Please input the quantity!' }]}
                                        >
                                            <Input
                                                type="number"
                                                value={product.quantity}
                                                onChange={(e) => handleChange('quantity', e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Card>
                                <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px" }}>
                                    Period Date
                                </Typography>

                                <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable>
                                    <Col xs={24} sm={24} md={24} >
                                        <div>
                                            <p style={{ fontWeight: 600 }}>Select Date Range:</p>
                                            <DatePicker.RangePicker
                                                value={[
                                                    startDateTime ? dayjs(startDateTime).tz('Asia/Kolkata') : null,
                                                    endDateTime ? dayjs(endDateTime).tz('Asia/Kolkata') : null
                                                ]}
                                                onChange={(dates) => {
                                                    const [start, end] = dates || [null, null];
                                                    setStartDateTime(start ? dayjs(start).tz('Asia/Kolkata') : null);
                                                    setEndDateTime(end ? dayjs(end).tz('Asia/Kolkata') : null);
                                                }}
                                                format="DD/MM/YYYY"
                                                style={{ width: '100%' }}
                                                disabledDate={(current) => {
                                                    return current && current < dayjs().startOf('day');
                                                }}
                                            />
                                        </div>
                                        <p style={{ fontWeight: 600 }}>Select Avaliable Time : </p>
                                        <div style={{ marginTop: '10px' }}>
                                            {times.map((time) => (
                                                <Tag
                                                    key={time}
                                                    onClick={() => handleTimeClick(time)}
                                                    style={{
                                                        background: selectedTimes.includes(time) ? "black" : "white",
                                                        color: selectedTimes.includes(time) ? "white" : "black",
                                                        cursor: "pointer",
                                                        marginBottom: "5px",
                                                        padding: "5px 10px",
                                                        transition: "background 0.3s, color 0.3s",
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        e.target.style.background = "gray";
                                                        e.target.style.color = "white";
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.target.style.background = selectedTimes.includes(time) ? "black" : "white";
                                                        e.target.style.color = selectedTimes.includes(time) ? "white" : "black";
                                                    }}
                                                >
                                                    {time}
                                                </Tag>
                                            ))}
                                        </div>
                                    </Col>
                                </Card>
                            </Col>

                            <Col xs={24} sm={24} md={12} style={{ paddingBottom: "40px" }}>
                                <Typography variant="p" gutterBottom component="div" sx={{ fontWeight: "500", fontSize: "16px" }}>
                                    Defects
                                </Typography>

                                <Card styles={{ body: { padding: 15, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" } }} hoverable>
                                    <Col xs={24} >

                                        <Button variant='outlined' onClick={handleAddRule} style={{ marginBottom: "10px" }}>Add Rule</Button>

                                        {showRulesInput && (

                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <Input
                                                    fullWidth
                                                    label={
                                                        <span style={{ color: "grey", fontWeight: "500", fontSize: "12px" }}>Rule</span>
                                                    }
                                                    name="rule"
                                                    value={rule}
                                                    onChange={handleRuleChange}

                                                    InputProps={{
                                                        endAdornment: (
                                                            <InfoIcon color="action" />
                                                        ),
                                                    }}
                                                />
                                                <Button variant='contained' onClick={handleRuleSubmit}>Submit Rule</Button>
                                            </div>

                                        )}
                                    </Col>
                                    <Col xs={24}>
                                        {
                                            product.rules.length > 0 && product.rules.map((rule, index) => (
                                                <>
                                                    {index === 0 &&

                                                        <Typography style={{ color: "grey", fontWeight: "600", fontSize: "14px" }}>Rules</Typography>

                                                    }

                                                    <Typography style={{ paddingLeft: 10, color: "black", fontWeight: "400", fontSize: "12px" }}>{index + 1} . {rule}</Typography>

                                                </>
                                            ))
                                        }
                                    </Col>
                                </Card>
                                <Col xs={24}>
                                    <Button type="primary" htmlType="submit" style={{ marginTop: '1rem', marginBottom: '1rem', position: "absolute", right: "0px" }}>
                                        Add Product
                                    </Button>
                                </Col>

                            </Col>


                        </Row>
                        <ToastContainer />
                    </Form>
                </Paper>
            </Box>
        )
    );
};
    export default AddProduct;
