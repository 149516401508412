import * as actionTypes from '../constants/cartConstants';

export const cartReducer = (state = { cartItems: [] }, action) => {
    switch (action.type) {
        case actionTypes.ADD_TO_CART:
            const item = action.payload;

            const existItem = state.cartItems.find(product => product.id === item.id);

            if (existItem) {
                return {
                    ...state, cartItems: state.cartItems.map(x => x.product === existItem.product ? item : x)
                }
            } else {
                return { ...state, cartItems: [...state.cartItems, item] }
            }
        case actionTypes.REMOVE_FROM_CART:
            return {
                ...state, cartItems: state.cartItems.filter(product => product.id !== action.payload)
            }
        case actionTypes.FETCH_CART_ITEMS:
            return {
                ...state, cartItems: action.payload
            }
        case actionTypes.PUBLISH_PRODUCT_REQUEST:
            return { ...state, publishProduct: { loading: true, error: null, data: null } };
        case actionTypes.PUBLISH_PRODUCT_SUCCESS:
            const updatedCartItems = state.cartItems.map(item => {
                if (item.id === action.payload.id) {
                    return { ...item, liststatus: action.payload.liststatus };
                }
                return item;
            });
            return {
                ...state,
                publishProduct: { loading: false, error: null, data: action.payload },
                cartItems: updatedCartItems
            };
        case actionTypes.PUBLISH_PRODUCT_FAIL:
            return { ...state, publishProduct: { loading: false, error: action.payload, data: null } };

        case actionTypes.UPDATE_RETURN_STATUS_REQUEST:
            return { ...state, returnStatus: { loading: true, error: null, data: null } };

        case actionTypes.UPDATE_RETURN_STATUS_SUCCESS:
            const updatedItems = state.cartItems.map(item => {
                if (item.id === action.payload.id) {
                    return action.payload; 
                }
                return item;
            });
            return {
                ...state,
                returnStatus: { loading: false, error: null, data: action.payload },
                cartItems: updatedItems
            };

        case actionTypes.UPDATE_RETURN_STATUS_FAIL:
            return { ...state, returnStatus: { loading: false, error: action.payload, data: null } };


        default:
            return state;
    }
}

