import * as actionTypes from '../constants/productConstant';

export const getProductReducer = (state = { products: [], metadata: {}, loading : false }, action) => {
    switch (action.type) {
        case actionTypes.GET_PRODUCTS_REQUEST:
        case actionTypes.LOAD_MORE_PRODUCTS_REQUEST:
            return { ...state, loading: true };
        case actionTypes.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload?.products,
                loading: false,
                metadata: action.payload?.paginationMetaData,
            };
        case actionTypes.LOAD_MORE_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: [...state.products, ...action.payload?.products],
                loading: false,
                metadata: action.payload?.paginationMetaData,
            };
        case actionTypes.GET_PRODUCTS_FAIL:
        case actionTypes.LOAD_MORE_PRODUCTS_FAIL:
            return { ...state, error: action.payload, loading: false };
        case actionTypes.RESET_PRODUCTS:
            return { products: [], metadata: {}, loading: false, error: null };
        default:
            return state;
    }
};

export const getProductDetailsReducer = (state = { product: {} }, action) => {

    switch (action.type) {
        case actionTypes.GET_PRODUCT_DETAILS_REQUEST:
            return { loading: true }
        case actionTypes.GET_PRODUCT_DETAILS_SUCCESS:
            return { loading: false, product: action.payload }
        case actionTypes.GET_PRODUCT_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case actionTypes.GET_PRODUCT_DETAILS_RESET:
            return {
                product: {}
            }
        default:
            return state
    }
}

export const changeProductStatusReducer = (state = { product: {} }, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_PRODUCT_STATUS_REQUEST:
            return { ...state, loading: true };

        case actionTypes.CHANGE_PRODUCT_STATUS_SUCCESS:
            return { ...state, loading: false, product: action.payload };

        case actionTypes.CHANGE_PRODUCT_STATUS_FAIL:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export const setCategoryReducer = (state = { category: '' }, action) => {
    switch (action.type) {
        case actionTypes.SET_CATEGORY_REQUEST:
            return { loading: true, category: '' };
        case actionTypes.SET_CATEGORY_SUCCESS:
            return { loading: false, category: action.payload };
        case actionTypes.SET_CATEGORY_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const locationReducer = (state = {
    location: null,
    loading: false,
    error: null,
}, action) => {
    switch (action.type) {
        case actionTypes.SET_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                location: action.payload,
            };
        case actionTypes.RESET_LOCATION:
            return {
                ...state,
                location: null,
            };
        case actionTypes.SET_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};