import { styled } from '@mui/material';
import { Descriptions, Typography } from 'antd';
import { addEllipsis } from '../../utils/util';

const StyledDescriptions = styled(Descriptions)({
  '.ant-descriptions-header': {
    marginBottom: '20px',
    '& .ant-descriptions-title': {
      fontSize: '18px',
      fontWeight: 600,
      color: '#2c3e50'
    }
  },
  '.ant-descriptions-item-label': {
    color: '#34495e',
    fontWeight: 500,
    backgroundColor: '#f8fafc'
  },
  '.ant-descriptions-item-content': {
    color: '#2c3e50',
    fontSize: '14px'
  },
  '.ant-descriptions-row > th, .ant-descriptions-row > td': {
    padding: '16px 24px'
  }
});

const ProductDetail = ({ product }) => {
    const items = [
        {
            label: 'Title',
            children: addEllipsis(product?.title.longTitle, 50),
            span: 2
        },
        {
            label: 'Category',
            children: <Typography.Text strong>{product?.category}</Typography.Text>
        },
        {
            label: 'Price',
            children: <Typography.Text type="success" strong>₹{product?.price * 24}</Typography.Text>
        },
        {
            label: 'Duration',
            children: (
                <>
                    <div>From: {new Date(product?.startdatetime).toLocaleString()}</div>
                    <div>To: {new Date(product?.enddatetime).toLocaleString()}</div>
                </>
            ),
            span: 2
        },
        {
            label: 'Location',
            children: product?.location,
            span: 2
        },
        {
            label: 'Tagline',
            children: <Typography.Text italic>{product?.tagline}</Typography.Text>,
            span: 2
        },
        {
            label: 'Address',
            children: product?.address,
            span: 2
        },
        {
            label: 'Quantity',
            children: <Typography.Text strong>{product?.quantity}</Typography.Text>
        }
    ];

    return (
        <StyledDescriptions
            title="Product Details"
            bordered
            column={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 3 }}
            items={items}
        />
    )
}

export default ProductDetail;