import React from 'react';

import { ChevronRight } from 'lucide-react';
import styled from '@emotion/styled';
import { Card } from 'antd';
import { navData } from '../../constant/data';



// Styled Components
const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  background: #f8f9fa;
`;

const CategoryCard = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: white;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

    .category-overlay {
      opacity: 1;
    }

    img {
      transform: scale(1.05);
    }

    .explore-text {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }

  .category-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }
`;

const CategoryContent = styled.div`
  padding: 20px;
  background: white;
`;

const CategoryTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
`;

const CategoryDescription = styled.p`
  margin: 8px 0;
  font-size: 14px;
  color: #718096;
`;

const ExploreButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #4a90e2;
  margin-top: 12px;
  opacity: 0.8;
  transition: all 0.3s ease;

  .explore-text {
    opacity: 0;
    transform: translateX(-10px);
    transition: all 0.3s ease;
  }

  &:hover {
    opacity: 1;
  }
`;

const CategoryBadge = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 6px 12px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  font-weight: 500;
  color: #4a90e2;
`;

const Categories = () => {
  // Sample data - replace with your navData


  return (
    <Card style={{  background: '#f8f9fa', border: 'none' }}>
      <h2 style={{ 
        fontSize: '24px', 
        fontWeight: '600', 
        color: '#2d3748',
        marginBottom: '24px',
      
      }}>
        Explore Categories
      </h2>
      <CategoryGrid>
        {navData.map((category) => (
          <CategoryCard key={category.text}>
            <ImageContainer>
              <img src={category.image} alt={category.text} />
              <div className="category-overlay" />
              <CategoryBadge>{category.badge}</CategoryBadge>
            </ImageContainer>
            <CategoryContent>
              <CategoryTitle>{category.text}</CategoryTitle>
              <CategoryDescription>{category.description}</CategoryDescription>
              <ExploreButton>
                <span className="explore-text">Explore Category</span>
                <ChevronRight size={16} />
              </ExploreButton>
            </CategoryContent>
          </CategoryCard>
        ))}
      </CategoryGrid>
    </Card>
  );
};

export default Categories;