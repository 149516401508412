import { Descriptions, Rate, Input } from 'antd';

const FeedbackForm = ({ item, feedback, setFeedback }) => {
  return (
    <>
      <Descriptions bordered>
        <Descriptions.Item label="Title" span={3}>{item?.title?.longTitle}</Descriptions.Item>
        <Descriptions.Item label="Price">₹{item?.price * 24}</Descriptions.Item>
        <Descriptions.Item label="Seller">{item?.productby}</Descriptions.Item>
      </Descriptions>
      <div style={{ marginTop: 20 }}>
        <h4>Rate Seller:</h4>
        <Rate
          allowHalf
          value={feedback.rating}
          onChange={(value) => setFeedback(prev => ({ ...prev, rating: value }))}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <h4>Comments:</h4>
        <Input.TextArea
          rows={4}
          value={feedback.comment}
          onChange={(e) => setFeedback(prev => ({ ...prev, comment: e.target.value }))}
          placeholder="Enter your feedback here..."
        />
      </div>
    </>
  );
};

export default FeedbackForm; 