import axiosInstance from '../../AxiosInsstance';
import { BASE_URL } from '../../constants';
import * as actionTypes from '../constants/productConstant';

export const getProducts = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_PRODUCTS_REQUEST });
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-all-products`, {
            params: { limit: 1000, offset: 0 },
        });
        dispatch({ type: actionTypes.GET_PRODUCTS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: actionTypes.GET_PRODUCTS_FAIL, payload: 'Failed to fetch products' });
    }
};

export const loadMoreProducts = (offset,startDate,endDate,category) => async (dispatch) => {
    dispatch({ type: actionTypes.LOAD_MORE_PRODUCTS_REQUEST });
    try {
        const response = await axiosInstance.get(`${BASE_URL}/get-more-products`, {
            params: { limit: 5, offset , startDate, endDate,category },
        });
        dispatch({ type: actionTypes.LOAD_MORE_PRODUCTS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: actionTypes.LOAD_MORE_PRODUCTS_FAIL, payload: 'Failed to load more products' });
    }
};

export const getProductDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: actionTypes.GET_PRODUCT_DETAILS_REQUEST });
        const { data } = await axiosInstance.get(`/product/${id}`);

        dispatch({ type: actionTypes.GET_PRODUCT_DETAILS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: actionTypes.GET_PRODUCT_DETAILS_FAIL, payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};
export const resetProducts = () => (dispatch) => {
    dispatch({ type: actionTypes.RESET_PRODUCTS });
};

export const removeProductDetails = () => (dispatch) => {
    dispatch({ type: actionTypes.GET_PRODUCT_DETAILS_RESET });
};

export const changeProductStatus = (id, status, originalPrice) => async (dispatch) => {
    try {
        dispatch({ type: actionTypes.CHANGE_PRODUCT_STATUS_REQUEST });

        const { data } = await axiosInstance.post('/productstatus', { id, status, originalPrice });

        dispatch({ type: actionTypes.CHANGE_PRODUCT_STATUS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({ type: actionTypes.CHANGE_PRODUCT_STATUS_FAIL, payload: error.response });
    }
};


export const setCategoryState = (category) => async (dispatch) => {
    try {
        dispatch({ type: actionTypes.SET_CATEGORY_REQUEST });

        // Here you would typically make a request to your API to set the category
        // For the sake of this example, let's assume the request is successful and the category is set

        dispatch({
            type: actionTypes.SET_CATEGORY_SUCCESS,
            payload: category
        });
    } catch (error) {
        dispatch({
            type: actionTypes.SET_CATEGORY_FAIL,
            payload: error.message
        });
    }
};

export const setLocationState = (location) => async (dispatch) => {
    try {
        dispatch({ type: actionTypes.SET_LOCATION_REQUEST });

        // Here you would typically make a request to your API to set the location
        // For the sake of this example, let's assume the request is successful and the location is set

        dispatch({
            type: actionTypes.SET_LOCATION_SUCCESS,
            payload: location
        });
    } catch (error) {
        dispatch({
            type: actionTypes.SET_LOCATION_FAIL,
            payload: error.message
        });
    }
};

export const resetLocationState = () => (dispatch) => {
    dispatch({ type: actionTypes.SET_LOCATION_SUCCESS, payload: null });
};