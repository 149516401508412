import axiosInstance from '../AxiosInsstance';

export const authenticateLogin = async (user) => {
    try {
        const response = await axiosInstance.post('/login', user);
        return response.data;
    } catch (error) {
        console.log('Error while calling login API: ', error);
    }
}

export const authenticateSignup = async (user) => {
    try {
        return await axiosInstance.post('/signup', user);
    } catch (error) {
        console.log('Error while calling Signup API: ', error);
    }
}

export const getProductById = async (id) => {
    try {
        return await axiosInstance.get(`/product/${id}`);
    } catch (error) {
        console.log('Error while getting product by id response', error);
    }
}

export const addProduct = async (product) => {
    try {
        return await axiosInstance.post('/addProduct', product);
    } catch (error) {
        console.log('Error while adding product response', error);
    }
}