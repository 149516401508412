import { Button, Divider, Box, Typography, styled } from '@mui/material';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';
import { addEllipsis } from '../../utils/util';

const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
};

const Component = styled(Box)`
    margin: 20px 0;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    overflow: hidden;
`;

const DealHeader = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f8f9fa;
`;

const DealTitle = styled(Typography)`
    font-size: 22px;
    font-weight: bold;
    color: #333;
`;

const TimerBox = styled(Box)`
    color: #777;
    display: flex;
    align-items: center;
`;

const Image = styled('img')(({ theme }) => ({
    width: 'auto',
    height: 180,
    borderRadius: '8px',
    marginBottom: '8px',
    transition: 'transform 0.2s ease',
    [theme.breakpoints.down('sm')]: { height: 130 }
}));

const StyledBox = styled(Box)`
    text-align: center;
    padding: 20px;
    margin: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    }
`;

const Text = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
    color: #444;
`;

const PriceText = styled(Typography)`
    font-size: 18px;
    color: #AD1E24;
    font-weight: bold;
`;

const Slide = ({ data, timer, title }) => {
    const renderer = ({ hours, minutes, seconds }) => (
        <Typography variant="body2" style={{ color: '#666' }}>{hours}:{minutes}:{seconds} Left</Typography>
    );

    return (
        <Component>
            <DealHeader>
                <DealTitle>{title}</DealTitle>
            </DealHeader>
            <Divider />
            <Carousel
                responsive={responsive}
                infinite
                autoPlay
                autoPlaySpeed={3000}
                keyBoardControl
                containerClass="carousel-container"
            >
                {data.map((item) => (
                    <Link to={`/product/${item.id}`} key={item.id} style={{ textDecoration: 'none' }}>
                        <StyledBox>
                            <Image src={item.url?.[0]} alt="product" />
                            <Text>{addEllipsis(item.title.longTitle)}</Text>
                            <PriceText>$ {(item.price * 24).toFixed(2)} / day</PriceText>
                        </StyledBox>
                    </Link>
                ))}
            </Carousel>
        </Component>
    );
};

export default Slide;
