import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Button, Card, Tag, DatePicker, Slider, List, Input, Select, notification, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addEllipsis } from '../../utils/util';
import { Link } from 'react-router-dom';
import EmptyCart from '../Cart/EmptyCart';
import Image from 'antd/es/image';
import { makeStyles } from '@material-ui/core/styles';
import { Rate } from 'antd';
import { getProducts, loadMoreProducts, resetProducts } from '../../redux/actions/productActions';
import dayjs from 'dayjs';
import './style.css';
import { useMediaQuery } from '@mui/material';
import { RiExpandRightFill } from "react-icons/ri";
import { RiContractLeftFill } from "react-icons/ri";
import { BASE_URL } from '../../constants';
import axiosInstance from '../../AxiosInsstance';
import InfiniteScroll from 'react-infinite-scroll-component';

const { Text } = Typography;
const { Option } = Select;

const useStyles = makeStyles({
    small: {
        '& .MuiInputBase-input': {
            padding: '10px 8px',
            // adjust as needed
        },
        '& .MuiInputLabel-root': {
            fontSize: '0.8rem', // adjust as needed
        },
    },
    // other styles...
});

function ViewAllProducts() {
    const { category } = useParams();
    const {products, metadata} = useSelector(state => state.getProducts);
    const [paramsProducts, setParamsProducts] = useState([]);
    const [displayCount, setDisplayCount] = useState(25);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [priceRange, setPriceRange] = useState([0, 100]);
    const [maxPrice, setMaxPrice] = useState(null); // State for max price
    const selectedCategory = useSelector(state => state.Category?.category);
    const classes = useStyles();
    const location = useSelector(state => state.location.location);
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [clickedCategory, setClickedCategory] = useState(''); // New state for clicked category
    const [categories, setCategories] = useState([]);
    const [isMinimized, setIsMinimized] = useState(true);
    const isMdUp = useMediaQuery((theme) => theme.breakpoints.up(960));
    const [loading, setLoading] = useState(true); // Loading state
    const [selectedItem, setSelectedItem] = useState('ALL');
    const startDate = useSelector(state => state.daterangepicker.startDate);
    const endDate = useSelector(state => state.daterangepicker.endDate);

    const handleClick = () => {
        setIsMinimized(!isMinimized);
    };

    useEffect(() => {
        dispatch(resetProducts());
        dispatch(loadMoreProducts(0, null,null, category));
    }, []);

    const handleLoadMore = () => {
        const offset = products.length;
        const total = metadata?.total;

        // Only load more if there are more products to load
        if (offset < total) {
            dispatch(loadMoreProducts(offset, startDate, endDate));
        }
    };

    useEffect(() => {
        const fetchTaglines = async () => {
            try {
                const response = await axiosInstance.get(`/fetchtaglines/${category}`);
                setCategories(['ALL', ...response.data]);
            } catch (error) {
                console.error('Error fetching taglines:', error);
            }
        };

        fetchTaglines();
    }, [category]);

    useEffect(() => {
        setLoading(true); // Set loading to true before filtering
        let filteredProducts = products;

      
        if (selectedEndDate) {
            filteredProducts = filteredProducts.filter(item => new Date(item.enddatetime) >= new Date(selectedEndDate));
        }
        if (location?.town) {
            filteredProducts = filteredProducts.filter(item => item.location === location?.town);
        }
        if (filteredProducts) {
            filteredProducts = filteredProducts.filter(item => item.status !== 'review' && item.status !== 'Rejected' && item.liststatus === 'published');
        }
        if (searchTerm) {
            filteredProducts = filteredProducts.filter(item => item.title.longTitle.toLowerCase().includes(searchTerm.toLowerCase()));
        }
      
        if (maxPrice !== null) {
            filteredProducts = filteredProducts.filter(item => item.price * 24 >= priceRange[0] && item.price * 24 <= priceRange[1]);
        }

        if (filteredProducts && filteredProducts.length > 0) {
            const count = Math.min(displayCount, filteredProducts.length);
            setParamsProducts(filteredProducts.slice(0, count));
        } else {
            setParamsProducts([]);
        }
        setLoading(false); // Set loading to false after filtering
    }, [products, category, displayCount, selectedEndDate, location, selectedCategory, clickedCategory, priceRange, maxPrice, searchTerm]);

    const handleScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            setDisplayCount(prevCount => prevCount + 20);
        }
    };


  const handleItemClick = (item) => {
    setSelectedItem(item);
    handleCategoryClick(item);
  };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);

    }, []);

    const handleDateChange = (date) => {
        setSelectedEndDate(date);
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handlePriceChange = (value) => {
        setPriceRange(value);
    };

    const handleMaxPriceChange = (value) => {
        setMaxPrice(value);
    };

    const handleCategoryClick = (category) => {
        setClickedCategory(category);
    };

    const getCardClassName = () => {
        if (isMdUp) {
            return isMinimized ? 'minimized' : '';
        }
        return 'minimized';
    };

    return (
        <div style={{ display: "flex", minHeight: "80vh", marginTop: isMdUp ? "70px" : "120px" }}>

            <div><Button variant="contained" onClick={handleClick} style={{ zIndex: 1000, position: "fixed", top: isMdUp ? "85px" : "135px" }}>
                {isMinimized ? <RiExpandRightFill /> : <RiContractLeftFill />}
            </Button>

                <div className={`collapse-container ${isMinimized ? 'collapsed' : ''}`}>
                    <div className="sidebar">
                        {/* Search Input */}
                        <Input.Search
                            placeholder="Search"
                            onSearch={handleSearch}
                            style={{ marginBottom: '20px', marginTop: "50px" }}
                        />

                        {/* Category List */}
                        <h3>Categories</h3>
                        <List
                            size="small"
                            bordered
                            dataSource={categories}
                            renderItem={item => (
                                <List.Item
                                    onClick={() => handleItemClick(item)}
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: item === selectedItem ? '#e6f7ff' : 'transparent'
                                    }}
                                >
                                    {item}
                                </List.Item>
                            )}
                            style={{ marginBottom: '20px' }}
                        />

                        {/* Price Slider */}
                        <h3>Price</h3>
                        <Select defaultValue="Select" style={{ width: 120, marginBottom: '20px' }} onChange={handleMaxPriceChange}>
                            <Option value={null}>Select</Option>
                            <Option value={100}>100</Option>
                            <Option value={1000}>1000</Option>
                            <Option value={10000}>10000</Option>
                            <Option value={1000000}>1000000</Option>
                        </Select>
                        <Slider
                            range
                            defaultValue={[20, 50]}
                            max={maxPrice || 100}
                            tooltip={{ formatter: value => `$${value}` }}
                            onChange={handlePriceChange}
                        />
                    </div>
                </div>
            </div>
            <Card className={`animated-card ${getCardClassName()}`} style={{ width: "100%" }} title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "40px" }}>
                    <span>{category} Products</span>
                    <Col>
                        <Row align="middle" style={{ gap: "10px" }} >
                            <DatePicker
                                className="small"
                                value={selectedEndDate ? dayjs(selectedEndDate) : null}
                                onChange={handleDateChange}
                                format="YYYY-MM-DD"
                                placeholder="End Date"
                            />
                        </Row>
                    </Col>
                </div>
            }>

                <Spin spinning={loading}>
                    <div style={{ backgroundColor: "white" }}>
                    <InfiniteScroll 
                    style={{ overflow: 'hidden' }} // Hide scrollbar
                    dataLength={paramsProducts.length} // Length of the products array
                    next={handleLoadMore} // Function to load more products
                    hasMore={products.length < (metadata?.total || 0)} // Determine if there's more to load
                    loader={<Spin></Spin>} // Loading indicator
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>No more products to show</b>
                        </p>
                    }
                >
                            <Row gutter={[16, 16]}>
                                {paramsProducts.length > 0 ?
                                    (
                                        paramsProducts.map((item, index) => (
                                            <Col xs={12} sm={12} md={12} lg={6} key={index}>
                                                <Card styles={{ body: { padding: 0 } }} hoverable >
                                                    <Link to={`/product/${item.id}`} style={{ textDecoration: "none" }}>
                                                        <div style={{ height: 290, display: 'flex', justifyContent: "center", flexDirection: 'column', textAlign: "left", padding: '5px 15px', overflow: 'hidden' }}>
                                                            <Image src={item.url?.[0] ? `${item.url[0]}` : ''} width='auto' height={160} style={{ objectFit: 'contain', overflow: 'hidden' }} />

                                                            <Text style={{ fontSize: 10, marginTop: 5, color: '#212121', opacity: '.6', textAlign: "left" }}>{item.tagline}</Text>
                                                            <Text style={{ fontSize: 14, marginTop: 5, fontWeight: 600, color: '#212121' }}>{addEllipsis(item.title.longTitle)}</Text>
                                                            <Rate style={{ fontSize: "13px", marginTop: 5 }} disabled allowHalf defaultValue={4.5} />
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div style={{ display: "flex", flexDirection: "column" }}>

                                                                    <Text style={{ fontSize: 12, marginTop: 5, color: '#212121', opacity: '.6' }}>{item.location}</Text>
                                                                </div>
                                                                <div style={{ display: "flex", alignItems: "end" }}>
                                                                    <Tag color="green" style={{ fontSize: 14, marginTop: 5 }}>{item.price * 24}/day</Tag>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                </Card>
                                            </Col>
                                        ))
                                    )
                                    : <EmptyCart />
                                }
                            </Row>
                        </InfiniteScroll>
                    </div>
                </Spin>
            </Card>
        </div>
    );
}

export default ViewAllProducts;
