import { styled, Box, Typography, Grid, Paper } from '@mui/material';
import ProductDetail from '../ItemDetails/ProductDetail';
import StatusItems from './StatusItems';

const Component = styled(Box)(({ theme }) => ({
  padding: '32px',
  background: '#f8fafc',
  minHeight: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '16px'
  }
}));

const Container = styled(Paper)(({ theme }) => ({
  background: '#FFFFFF',
  borderRadius: '12px',
  boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    margin: 0
  }
}));

const RightContainer = styled(Grid)(({ theme }) => ({
  padding: '32px',
  '& .product-title': {
    fontSize: '32px',
    fontWeight: 600,
    color: '#1a1a1a',
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    }
  },
  '& .price-tag': {
    fontSize: '24px',
    color: '#059669',
    fontWeight: 500,
    marginBottom: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px'
    }
  }
}));

const ReviewProduct = ({ product, handleApprove, handleReject }) => {
    return (
        <Component>
            { product && Object.keys(product).length &&
                <Container>
                    <Grid container spacing={3}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <StatusItems 
                                product={product} 
                                handleApprove={handleApprove} 
                                handleReject={handleReject} 
                            />
                        </Grid>
                        <RightContainer item lg={8} md={8} sm={12} xs={12}>
                            <Typography className="product-title">
                                {product.title.longTitle}
                            </Typography>
                            <Typography className="price-tag">
                                Rent: ₹{product?.price * 24} / day
                            </Typography>
                            <ProductDetail product={product} />
                        </RightContainer>
                    </Grid>
                </Container>
            }   
        </Component>
    )
}

export default ReviewProduct;