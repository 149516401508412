import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Chip,
  Modal,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Fade, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeUserStatus, getUsers } from "../../redux/actions/userActions";
import ReviewGovernmentId from "./UserReview";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, notification, Spin } from "antd";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginTop: 50,
  },
  modal: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: "flex",
    flexDirection: "column",
  },
}));

const CenteredModal = styled(Modal)({
  padding: "50px 0px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'scroll',
});

export default function UserAdmin() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const getUsersData = useSelector(state => state.getUsers);
  const { users, loading } = getUsersData;
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    dispatch(getUsers()); 
  }, []);

  useEffect(() => {
    if (users) {
      setRows(users);
    }
  }, [users]);

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = async (event, id) => {
    event.stopPropagation();
    
    try {
        const result = await dispatch(changeUserStatus(id, 'Approved'));
        
    } catch (error) {
        notification.error({
            message: 'Error',
            description: `Error approving user: ${error.message}`,
            placement: 'topRight',
        });
    }
};
  const handleReject = (event, id) => {
    event.stopPropagation();
    dispatch(changeUserStatus(id, 'Rejected'));
    setRows(rows.map(row => row._id === id ? { ...row, status: 'Rejected' } : row));
    setOpen(false);
  };

  return (
    <Spin spinning={loading}>
      <TableContainer component={Paper} style={{ padding: "40px" }}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Avatar</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Bio</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.map((row) => (
              <TableRow key={row?._id} onClick={() => handleRowClick(row)}>
                <TableCell>
                  <Avatar src={row?.avatar} />
                </TableCell>
                <TableCell>{row?.firstname}</TableCell>
                <TableCell>{row?.lastname}</TableCell>
                <TableCell>{row?.email}</TableCell>
                <TableCell>{row?.phone}</TableCell>
                <TableCell>{row?.city}</TableCell>
                <TableCell>{row?.state}</TableCell>
                <TableCell>{row?.bio}</TableCell>
                <TableCell>
                  {row?.status === "review" ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(event) => handleApprove(event, row?._id)}
                      >
                        <CheckOutlined />
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(event) => handleReject(event, row?._id)}
                      >
                        <CloseOutlined />
                      </Button>
                    </>
                  ) : (
                    <Chip
                      variant="outlined"
                      label={row?.status}
                      color={row?.status === "Approved" ? "primary" : "secondary"}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CenteredModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
          }}
          style={{ overflow: 'scroll' }}
        >
          <Fade in={open}>
            <div className={classes.modal}>
              <ReviewGovernmentId user={selectedRow} handleReject={handleReject} handleApprove={handleApprove} />
            </div>
          </Fade>
        </CenteredModal>
      </TableContainer>
    </Spin>
  );
}