import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Modal, Fade, Chip, Typography, Box, IconButton
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeProductStatus, getProducts as listProducts } from "../../redux/actions/productActions";
import ReviewProduct from "./Reviewproduct";
import { styled } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Spin, Input, notification } from "antd";

const CenteredModal = styled(Modal)({
  padding: "50px 0px",
  alignItems: 'center',
  justifyContent: 'center',
  overflow: "scroll"
});

const StyledTableContainer = styled(TableContainer)({
  padding: "24px",
  height: "calc(100vh - 48px)",
  overflow: "auto",
  '& .MuiTable-root': {
    minWidth: 750,
  },
  '& .MuiTableCell-head': {
    backgroundColor: '#f5f5f5',
    fontWeight: 600,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  }
});

const ActionButton = styled(Button)({
  margin: '0 4px',
  borderRadius: '6px',
  height: '36px',
  width: '36px',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&.approve': {
    backgroundColor: '#52c41a',
    '&:hover': {
      backgroundColor: '#389e0d',
    },
  },
  '&.reject': {
    backgroundColor: '#ff4d4f',
    '&:hover': {
      backgroundColor: '#cf1322',
    },
  }
});

const ProductImage = styled('img')({
  width: '60px',
  height: '60px',
  borderRadius: '8px',
  objectFit: 'cover',
});

const StatusChip = styled(Chip)(({ status }) => ({
  borderRadius: '16px',
  fontWeight: 500,
  padding: '0 12px',
  backgroundColor: status === 'Approved' ? '#f6ffed' : '#fff1f0',
  color: status === 'Approved' ? '#52c41a' : '#ff4d4f',
  borderColor: status === 'Approved' ? '#b7eb8f' : '#ffa39e',
}));

export default function Admin() {
  const [open, setOpen] = useState(false);
  const [originalPrice, setOriginalPrice] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const getProducts = useSelector(state => state.getProducts);
  const { products, loading } = getProducts;
  const [rows, setRows] = useState(products);
  const dispatch = useDispatch();

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleApprove = async (event, id) => {
    event.stopPropagation();
    const originalPriceValue = originalPrice[id];

    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, status: 'Approved', originalPrice: originalPriceValue };
      }
      return row;
    });

    try {
      await dispatch(changeProductStatus(id, 'Approved', originalPriceValue));
      setRows(updatedRows);
      setOpen(false);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Failed to update product status',
      });
    }
  };

  const handleReject = async (event, id) => {
    event.stopPropagation();
    try {
      const updatedRows = rows.map(row => {
        if (row.id === id) {
          return { ...row, status: 'Rejected' };
        }
        return row;
      });

      await dispatch(changeProductStatus(id, 'Rejected'));
      setRows(updatedRows);
      setOpen(false);
      notification.success({
        message: 'Success',
        description: 'Product status updated to Rejected',
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to update product status',
      });
    }
  };

  useEffect(() => {
    setRows(products);
  }, [products]);

  useEffect(() => {
    dispatch(listProducts());
  }, [])

  const handleInputChange = (id, value) => {
    setOriginalPrice(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  return (
    <Spin spinning={loading} tip="Loading products...">
      <StyledTableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Tagline</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow 
                key={row.id} 
                onClick={() => handleRowClick(row)}
                hover
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#fafafa' }
                }}
              >
                <TableCell>
                  <ProductImage src={`${row.url?.[0]}`} alt={row.name} />
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{row.title.shortTitle}</Typography>
                </TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>{parseFloat(row.price * 24).toFixed(2)}</TableCell>
                <TableCell>{row.quantity}</TableCell>
                <TableCell>{row.location}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.tagline}</TableCell>
                <TableCell>
                  {row.status === "review" ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Input
                        placeholder="Original Price"
                        value={originalPrice[row.id] || ''}
                        onChange={(e) => handleInputChange(row.id, e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                        style={{ 
                          width: '120px',
                          marginRight: '8px',
                          borderRadius: '6px'
                        }}
                      />
                      <ActionButton
                        className="approve"
                        onClick={(event) => handleApprove(event, row?.id)}
                        disabled={!originalPrice[row.id]}
                      >
                        <CheckOutlined style={{ color: 'white' }} />
                      </ActionButton>
                      <ActionButton
                        className="reject"
                        onClick={(event) => handleReject(event, row?.id)}
                      >
                        <CloseOutlined style={{ color: 'white' }} />
                      </ActionButton>
                    </Box>
                  ) : (
                    <StatusChip
                      variant="outlined"
                      label={row.status}
                      status={row.status}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <CenteredModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition

          BackdropProps={{
            timeout: 500,
          }}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.75)'
            }
          }}
        >
          <Fade in={open}>
            <Box sx={{
              backgroundColor: "white",
              borderRadius: '8px',
              position: 'relative',
              width: '90%',
              maxWidth: '1200px',
              margin: '32px auto',
              padding: '24px',
              maxHeight: '90vh',
              overflow: 'auto'
            }}>
              <ReviewProduct 
                product={selectedRow} 
                handleApprove={handleApprove} 
                handleReject={handleReject} 
              />
              <IconButton
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: 'grey.500',
                  '&:hover': {
                    color: 'grey.700',
                  }
                }}
              >
                <IoMdClose size={24} />
              </IconButton>
            </Box>
          </Fade>
        </CenteredModal>
      </StyledTableContainer>
    </Spin>
  );
}
