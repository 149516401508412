import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  startDate: null,
  endDate: null
}

const DatePickerStore = createSlice({
  name: 'datePicker',
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    resetDates: (state) => {
      state.startDate = null;
      state.endDate = null;
    }
  }
});

export const { setStartDate, setEndDate, resetDates } = DatePickerStore.actions

export default DatePickerStore.reducer