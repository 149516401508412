import React from 'react';
import './style.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import Logo from "../../assets/rentinstant.png"
import { Link } from 'react-router-dom';

function ApplicationFooter() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="footer-col">
                        <h4>company</h4>
                        <ul>
                            <li style={{ display: "flex", justifyContent: "left", alignItems: "center", fontFamily: "Happy Monkey", fontWeight: 600, color: "#bbbbbb" }} ><img src={Logo} width={50} alt="" /> <span style={{ color: "#bbbbbb" }}>Rent Instant</span></li>
                            <li style={{ color: "#bbbbbb", marginLeft: "10px", fontSize: "10px", fontFamily: "Happy Monkey" }}>Rental Application : Unlocking Exceptional Experiences</li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h4>categories</h4>
                        <ul>
                            <li><Link to="/category/Electronics">Electronics</Link></li>
                            <li><Link to="/category/Kitchen">Kitchen</Link></li>
                            <li><Link to="/category/Fashion">Fashion</Link></li>
                            <li><Link to="/category/Tools">Tools</Link></li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h4>online shop</h4>
                        <ul>
                        <li><Link to="/category/Vehicles">Vehicles</Link></li>
                        <li><Link to="/category/Toy%&%Gaming">Toy & Gaming</Link></li>
                        <li><Link to="/category/Furniture">Furniture</Link></li>
                        <li><Link to="/category/Sports">Sports</Link></li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h4>follow us</h4>
                        <div className="social-links">
                            <a href="#"><FaFacebookF /></a>
                            <a href="#"><FaTwitter /></a>
                            <a href="#"><FaInstagram /></a>
                            <a href="#"><FaLinkedinIn /></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default ApplicationFooter;