import MapBoxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useControl } from 'react-map-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const Geocoder = ({ setLocation }) => {
  const ctrl = new MapBoxGeocoder({
    accessToken: "pk.eyJ1IjoiYmFubnU3NzMiLCJhIjoiY20wZmdsejlqMHo2bjJyc2RoY245MXB5aSJ9.9bW8b_fkzFNc8HiuOcYPjw",
    marker: false,
    collapsed: true,
  });
  
  useControl(() => ctrl);
  
  ctrl.on('result', (e) => {
    const coords = e.result.geometry.coordinates;
    const { place_name, context } = e.result;

    let town = '';
    let state = '';
    let country = '';

    // Extract relevant info from context
    context.forEach((c) => {
      if (c.id.includes('place')) town = c.text;
      if (c.id.includes('region')) state = c.text;
      if (c.id.includes('country')) country = c.text;
    });

    setLocation({
      lng: coords[0],
      lat: coords[1],
      town,
      state,
      country,
      address: place_name,
    });
  });
  
  return null;
};

export default Geocoder;
