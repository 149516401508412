import { useState } from 'react';
import { Typography, Drawer, MenuItem, List, ListItem, TextField, FormControl, InputLabel, Select, Button, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@mui/icons-material';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

const useStyles = makeStyles({
    preview: {
        display: 'flex',
        flexDirection: 'column',
    },
});

const StyledDrawer = styled(Drawer)`
    .MuiDrawer-paper {
        min-width: 350px;
        padding: 20px;
    }
`;

const StyledListItem = styled(ListItem)`
    padding: 10px 20px;
`;

const GovernmentID = ({ govIdOpen, handleGovIdClose, govIdType, setGovIdType, govIdNumber, setGovIdNumber, handleGovId, setGovImage, govImage,handleBackGovId,message,showSnackbar,  handleSnackbarClose }) => {
    const classes = useStyles();

    const handleGovIdTypeChange = (event) => {
        setGovIdType(event.target.value);
    };

    const handleGovIdNumberChange = (event) => {
        setGovIdNumber(event.target.value);
    };

    return (
        <StyledDrawer
            anchor='right'
            open={govIdOpen}
            onClose={handleGovIdClose}
            disableBackdropTransition={false}

            
        >
            <div style={{ width: "100px" }}>
                <IconButton onClick={handleBackGovId}>
                    <ArrowBack />
                </IconButton>
            </div>
            <List>
                <StyledListItem>
                    <Typography>Upload your government ID</Typography>
                </StyledListItem>
                <StyledListItem>
                    <FormControl>
                        <InputLabel id="gov-id-type-label">ID Type</InputLabel>
                        <Select
                            labelId="gov-id-type-label"
                            id="gov-id-type"
                            value={govIdType}
                            onChange={handleGovIdTypeChange}
                            fullWidth
                        >
                            <MenuItem value={'license'}>License</MenuItem>
                            <MenuItem value={'panCard'}>Pan Card</MenuItem>
                            <MenuItem value={'aadharCard'}>Aadhar Card</MenuItem>
                            <MenuItem value={'passport'}>Passport</MenuItem>
                            <MenuItem value={'voterId'}>Voter ID</MenuItem>
                            {/* Add more MenuItem components for other ID types */}
                        </Select>
                    </FormControl>
                </StyledListItem>
                <StyledListItem>
                    <TextField
                        label="Gov ID No"
                        value={govIdNumber}
                        onChange={handleGovIdNumberChange}
                    />
                </StyledListItem>
                <StyledListItem>
                    <DropzoneArea
                    dropzoneClass=''
                        acceptedFiles={['image/*']}
                        dropzoneText={"Drag and drop an image here or click"}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        onChange={(files) => {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                setGovImage(reader.result);
                            };
                            if (files[0]) {
                                reader.readAsDataURL(files[0]);
                            }
                        }}
                        classes={{
                            preview: classes.preview,
                        }}
                    />
                </StyledListItem>
                <ListItem>
                    <Button variant="contained" color="primary" onClick={handleGovId}>Save</Button>
                </ListItem>
            </List>
            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}   anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </StyledDrawer>
    )
}

export default GovernmentID;