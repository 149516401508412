// src/reducers/myOrdersReducer.js

import { CANCEL_ORDER_FAIL, CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS, FETCH_ORDERS_FAIL, FETCH_ORDERS_REQUEST, FETCH_ORDERS_SUCCESS, UPDATE_ORDER_FIELD } from "../constants/myOrdersContant";



export const MyOrdersReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case FETCH_ORDERS_REQUEST:
            return { loading: true, orders: [] };
        case FETCH_ORDERS_SUCCESS:
            return { loading: false, orders: action.payload };
        case FETCH_ORDERS_FAIL:
            return { loading: false, error: action.payload };
        case UPDATE_ORDER_FIELD:
            return {
                ...state,
                orders: state.orders.map(order =>
                    order.session_id === action.payload.session_id
                        ? { ...order, [action.payload.key]: action.payload.value }
                        : order
                )
            };
        case CANCEL_ORDER_REQUEST:
            return { ...state, loading: true };
        case CANCEL_ORDER_SUCCESS:
            return { ...state, loading: false, success: true };
        case CANCEL_ORDER_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

