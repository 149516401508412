import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    showVerifyUserLogin: false

}

const verifyUserLogin = createSlice({
  name: "VerifyUserLogin",
  initialState,
  reducers: {
    setShowVerifyUserLogin: (state, action) => {
      state.showVerifyUserLogin = action.payload;
    }
  }
});

export const {setShowVerifyUserLogin} = verifyUserLogin.actions

export default verifyUserLogin.reducer