import { useState } from 'react';
import { Button, Box, styled } from '@mui/material';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const LeftContainer = styled(Box)(({ theme }) => ({
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down('md')]: {
        padding: '24px'
    }
}));

const ImageContainer = styled(Box)({
    position: 'relative',
    width: '100%',
    aspectRatio: '1',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1)',
});

const MainImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

const ThumbnailContainer = styled(Box)({
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap'
});

const Thumbnail = styled('img')({
    width: 'calc(20% - 6px)',
    aspectRatio: '1',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    objectFit: 'cover',
    '&:hover': {
        transform: 'scale(1.05)',
    }
});

const ButtonContainer = styled(Box)({
    display: 'flex',
    gap: '12px',
    marginTop: '16px'
});

const ActionButton = styled(Button)({
    flex: 1,
    padding: '12px',
    borderRadius: '8px',
    fontWeight: 600,
    textTransform: 'none',
    '&.approve': {
        backgroundColor: '#059669',
        '&:hover': {
            backgroundColor: '#047857'
        }
    },
    '&.reject': {
        backgroundColor: '#dc2626',
        '&:hover': {
            backgroundColor: '#b91c1c'
        }
    }
});

const StatusItems = ({ product, handleApprove, handleReject }) => {
    const { id, url } = product;
    const [mainImage, setMainImage] = useState(url?.[0] ? `${url[0]}` : null);

    return (
        <LeftContainer>
            <ImageContainer>
                <MainImage src={mainImage} alt="Product" />
            </ImageContainer>

            <ThumbnailContainer>
                {url.map((imageUrl, index) => (
                    <Thumbnail
                        key={index}
                        src={`${imageUrl}`}
                        onClick={() => setMainImage(`${imageUrl}`)}
                        alt={`Thumbnail ${index + 1}`}
                    />
                ))}
            </ThumbnailContainer>

            <ButtonContainer>
                <ActionButton
                    className="approve"
                    onClick={(event) => handleApprove(event, id)}
                    variant="contained"
                    startIcon={<CheckCircleOutlined />}
                >
                    Approve
                </ActionButton>
                <ActionButton
                    className="reject"
                    onClick={(event) => handleReject(event, id)}
                    variant="contained"
                    startIcon={<CloseCircleOutlined />}
                >
                    Reject
                </ActionButton>
            </ButtonContainer>
        </LeftContainer>
    )
}

export default StatusItems;