import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import {thunk} from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import { cartReducer } from './reducers/cartReducer';
import { getProductDetailsReducer, getProductReducer, locationReducer, setCategoryReducer } from './reducers/productReducer';
import { FetchUserData, getUsersReducer } from './reducers/userReducer';
import { Category } from '@mui/icons-material';
import { reviewReducer } from './reducers/reviewReducer';
import { MyOrdersReducer, transactionReducer } from './reducers/myOdersReducer';
import DaterangePicker from './actions/DatePickerStore';
import searchModalDrawer from './searchModalDrawer';
import verifyUserLogin from './verifyUserLogin';


const reducer = combineReducers({
    cart: cartReducer,
    getProducts: getProductReducer,
    getProductDetails: getProductDetailsReducer,
    getUsers: getUsersReducer,
    Category: setCategoryReducer,
    location : locationReducer,
    review : reviewReducer,
    user : FetchUserData,
    myOrders : MyOrdersReducer,
    daterangepicker : DaterangePicker,
    searchModalDrawer,
    verifyUserLogin
})


const middleware = [thunk];
const initialState = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(
    reducer, 
    initialState,
    composeEnhancers(
        applyMiddleware( ...middleware ),
    )
);

export default store;