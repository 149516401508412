import React from 'react';
import Button from "@material-ui/core/Button";

export default function ReviewGovernmentId({ user ,handleApprove , handleReject}) {

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <h2>Government ID: {user?.government?.id}</h2>
            <h3>Type: {user?.government?.type}</h3>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={user?.government?.image} alt="Government ID" style={{ width: "400px" }} />
            </div>

            <>
                <Button variant="contained" color="primary" onClick={(event) => handleApprove(event, user?._id)}>
                    Approve
                </Button>
                <Button variant="contained" color="secondary" onClick={(event) => handleReject(event, user?._id)}>
                    Reject
                </Button>
            </>

        </div>
    );
}