import * as actionTypes from '../constants/cartConstants';
import axiosInstance from '../../AxiosInsstance';


export const addToCart = (id, quantity, user) => async (dispatch) => {
    try { 
        const { data } = await axiosInstance.get(`/product/${id}`);

        dispatch({ type: actionTypes.ADD_TO_CART, payload: { ...data, quantity } });

        // Store product in MongoDB
        await axiosInstance.post('/additemtocart', {
            user: {
                email: user.email
            },
            cartItems: [{
                product: {
                    id: data.id
                },
                price: {
                    price: data.price
                }
            }]
        });

    } catch (error) {
        console.log('Error while calling cart API:', error);
    }
};

export const fetchCartItems = (userEmail) => async (dispatch) => {
    try {
        const { data } = await axiosInstance.get(`/getcartitems/${userEmail}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        dispatch({ type: actionTypes.FETCH_CART_ITEMS, payload: data });

    } catch (error) {
        console.log('Error while fetching cart items', error);
    }
};
export const removeFromCart = (userId, productId) => async (dispatch) => {
    try {
        await axiosInstance.delete(`/removeitemfromcart/${userId}/${productId}`);
        dispatch({
            type: actionTypes.REMOVE_FROM_CART,
            payload: productId,
        });
    } catch (error) {
        console.error(error);
    }
};

export const publishProductItem = (productId, message, endDateTime, startDateTime) => async (dispatch) => {
    try {
        dispatch({ type: actionTypes.PUBLISH_PRODUCT_REQUEST });

        const response = await axiosInstance.put(`/product-publishstatus/${productId}`, {
            message,
            enddatetime: endDateTime,
            startdatetime : startDateTime
        });

        if (response.status !== 200) {
            throw new Error('Failed to publish product');
        }

        dispatch({ type: actionTypes.PUBLISH_PRODUCT_SUCCESS, payload: response.data });
        
    } catch (error) {
        dispatch({ type: actionTypes.PUBLISH_PRODUCT_FAIL, payload: error.message });
    }
};

export const updateReturnStatus = (id) => async (dispatch) => {
    try {
        dispatch({ type: actionTypes.UPDATE_RETURN_STATUS_REQUEST });

        const response = await axiosInstance.put(`/update-return-status/${id}`);

        dispatch({ type: actionTypes.UPDATE_RETURN_STATUS_SUCCESS, payload: response.data });

    } catch (error) {
        dispatch({ type: actionTypes.UPDATE_RETURN_STATUS_FAIL, payload: error.message });
    }
};