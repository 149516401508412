import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@material-ui/core';
import { Paper, Typography } from '@material-ui/core';
import { Box, Grid, styled, useMediaQuery } from '@mui/material';
import OrderItem from './OrdersCard';
import EmptyCart from '../Cart/EmptyCart';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';
import { fetchOrders } from '../../redux/actions/myOrdersActions';
import { useDispatch, useSelector } from 'react-redux';

const Component = styled(Grid)(({ theme }) => ({
    padding: '20px 0px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
        padding: '10px 0'
    }
}));

const LeftComponent = styled(Grid)(({ theme }) => ({
    paddingRight: 15,
    marginBottom: "10px",
    display: 'flex',
    flexDirection: 'column',
    gap: "10px",
    [theme.breakpoints.down('sm')]: {
        marginBottom: 15,
        padding: 0
    }
}));

const BackButtonWrapper = styled('div')({
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: '#f0f0f0',
    },
});

const filterOptions = [
    { value: 'host', label: 'Host' },
    { value: 'myrents', label: 'MyRents' },
];

const MyOrder = () => {
    const [isProductLoading, setIsProductLoading] = useState(false);
    const { user, isLoading } = useAuth0();
    const [filter, setFilter] = useState('host');
    const isMdUp = useMediaQuery((theme) => theme.breakpoints.up(960));
    const orders = useSelector(state => state.myOrders.orders);
    const history = useNavigate();
    const dispatch = useDispatch();

    const handleBackClick = () => {
        history('/');
    };

    const filteredOrders = orders?.filter(order => {
        const userEmail = user.email.trim().toLowerCase();
        const orderProductby = order.productby.trim().toLowerCase();
        if (filter === 'host') {
            return orderProductby === userEmail;
        } else if (filter === 'myrents') {
            return orderProductby !== userEmail;
        }
        return false;
    });

    useEffect(() => {
        if (user?.email) {
            dispatch(fetchOrders(user.email));
        }
    }, [dispatch, user]);

    return (
        <>
            <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3, py: 5 ,}}>
                <Paper style={{ maxWidth: 1200, margin: 'auto', overflow: 'hidden', padding: 20,  marginTop : isMdUp ? "0px" : "50px" }}>
                    {isProductLoading ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh", width: "100vw" }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <BackButtonWrapper onClick={handleBackClick}>
                                    <ArrowLeftOutlined />
                                </BackButtonWrapper>
                                <div>
                                    <Typography variant="p" gutterBottom component="div" style={{ fontSize: "10px", color: "grey" }}>
                                        Back to Home
                                    </Typography>
                                    <Typography variant="p" gutterBottom component="div" style={{ fontSize: "14px", fontWeight: "600" }}>
                                        My Rents
                                    </Typography>
                                </div>
                            </div>
                            <Col span={24}>
                                <div style={{ padding: '15px 0px', background: '#fff', display: 'flex', justifyContent: 'space-between' }}>
                                    <Radio.Group value={filter} onChange={e => setFilter(e.target.value)}>
                                        {filterOptions.map(option => (
                                            <Radio.Button key={option.value} value={option.value}>{option.label}</Radio.Button>
                                        ))}
                                    </Radio.Group>
                                </div>
                            </Col>
                            {filteredOrders.length ? (
                                <Component container>
                                    <LeftComponent item lg={12} md={12} sm={12} xs={12} gap={10}>
                                        {filteredOrders.map((item, index) => (
                                            <OrderItem filter={filter} key={index} item={item} />
                                        ))}
                                    </LeftComponent>
                                </Component>
                            ) : (
                                isLoading ? (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh", width: "100vw" }}>
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <EmptyCart />
                                )
                            )}
                        </>
                    )}
                </Paper>
            </Box>
        </>
    );
};

export default MyOrder;