import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Card, Tag, DatePicker, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addEllipsis } from '../../utils/util';
import { Link } from 'react-router-dom';
import EmptyCart from '../Cart/EmptyCart';
import Image from 'antd/es/image';
import { styled, useMediaQuery } from '@mui/material';
import { Rate } from 'antd';
import { loadMoreProducts, resetProducts } from '../../redux/actions/productActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClockLoader } from 'react-spinners';

const { Text } = Typography;

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`;

function HomeScreenProducts() {
    const { products, metadata , loading} = useSelector(state => state.getProducts);
    const [paramsProducts, setParamsProducts] = useState([]);
    const selectedCategory = useSelector(state => state.Category?.category);
    const location = useSelector(state => state.location.location);
    const isMdUp = useMediaQuery(theme => theme.breakpoints.down(960));
    const startDate = useSelector(state => state.daterangepicker.startDate);
    const endDate = useSelector(state => state.daterangepicker.endDate);
    
    const dispatch = useDispatch();

    const handleLoadMore = () => {
        const offset = products.length;
        const total = metadata?.total;

        // Only load more if there are more products to load
        if (offset < total) {
            dispatch(loadMoreProducts(offset, startDate, endDate));
        }
    };

    useEffect(() => {
        let filteredProducts = products;

        // Apply location filtering
        if (location?.town) {
            filteredProducts = filteredProducts.filter(item => item.location === location?.town);
        }

        // Filter out products with specific status
        filteredProducts = filteredProducts.filter(item => item.status !== 'review' && item.status !== 'Rejected' && item.liststatus === 'published');
        
        setParamsProducts(filteredProducts);
    }, [products, location, selectedCategory]);


    useEffect(() => {
        if(!loading)
        {
            dispatch(resetProducts());
            dispatch(loadMoreProducts(0, startDate, endDate,selectedCategory));
        }
    },[startDate, endDate,selectedCategory])

    
    return (
        <StyledCard title={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>All ITEMS</span>
               
            </div>
        }>
            <div style={{ backgroundColor: "white" }}>
                <InfiniteScroll
                    dataLength={paramsProducts.length} // Length of the products array
                    next={handleLoadMore} // Function to load more products
                    hasMore={products.length < (metadata?.total || 0)} // Determine if there's more to load
                    loader={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
                    <ClockLoader  speedMultiplier={3} size={75} />
                    <h4>Loading...</h4>
                  </div>} // Loading indicator
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>No more products to show</b>
                        </p>
                    }
                >
                    <Row >
                        {paramsProducts.length > 0 ?
                            paramsProducts.map((item, index) => (
                                <Col xs={12} sm={12} md={6} lg={4} key={index}>
                                    <Card hoverable>
                                        <Link to={`/product/${item.id}`} style={{ textDecoration: 'none' }}>
                                            <div style={{ height: 320, display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'left', padding: '5px 15px', overflow: 'hidden' }}>
                                                <Image src={`${item.url?.[0]}`} width="auto" height={160} style={{ objectFit: 'contain', overflow: 'hidden' }} />
                                                <Text style={{ fontSize: 10, marginTop: 5, color: '#212121', opacity: '.6', textAlign: 'left' }}>{item.tagline}</Text>
                                                <Text style={{ fontSize: isMdUp ? 12 : 14, marginTop: 5, fontWeight: 600, color: '#212121' }}>{addEllipsis(item.title.longTitle)}</Text>
                                                <Rate style={{ fontSize: '13px', marginTop: 5 }} disabled allowHalf defaultValue={4.5} />
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: isMdUp ? 10 : 0 }}>
                                                        <Text style={{ fontSize: isMdUp ? 9 : 12, marginTop: 5, color: '#212121', opacity: '.6' }}>{item.location}</Text>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'end', marginLeft: isMdUp ? 0 : 10 }}>
                                                        <Tag color="green" style={{ fontSize: 14, marginTop: 5 }}>{item.price * 24}/day</Tag>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </Card>
                                </Col>
                            ))
                            : <EmptyCart />
                        }
                    </Row>
                </InfiniteScroll>
            </div>
        </StyledCard>
    );
}

export default HomeScreenProducts;
