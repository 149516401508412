import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, DatePicker, Button } from 'antd';
import { setEndDate, setStartDate, resetDates } from '../redux/actions/DatePickerStore';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const DateModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [dates, setDates] = useState([]);

  const handleSave = () => {
    if (dates.length === 2) {
      dispatch(setStartDate(dates[0].toISOString()));
      dispatch(setEndDate(dates[1].toISOString()));
    }
    onClose();
  };

  const handleReset = () => {
    setDates([]);
    dispatch(resetDates());
  };

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < dayjs().startOf('day');
  };

  return (
    <Modal
      title="Select Dates"
      open={isOpen}
      onOk={handleSave}
      onCancel={onClose}
      minDate={dayjs().startOf('day')}
      footer={[
        <Button key="reset" onClick={() => {handleReset(); onClose()}}>
          Reset
        </Button>,
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
    >
      <RangePicker
        style={{ width: '100%' }}
        value={dates}
        onChange={(dates) => setDates(dates)}
        disabledDate={disabledDate}
      />
    </Modal>
  );
};

export default DateModal;