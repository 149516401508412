// redux/reducers/reviewReducer.js
export const reviewReducer = (state = { reviews: [] }, action) => {
    switch (action.type) {
        case 'GET_REVIEWS_REQUEST':
            return { loading: true, reviews: [] };
        case 'GET_REVIEWS_SUCCESS':
            return { loading: false, reviews: action.payload };
        case 'GET_REVIEWS_FAIL':
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
