import { Typography, Box, styled, useMediaQuery } from '@mui/material';
import { navData } from '../../constant/data';
import { GoGear } from "react-icons/go";
import { MdSportsScore } from "react-icons/md";
import { LuPartyPopper } from "react-icons/lu";
import { LiaBicycleSolid, LiaToolsSolid } from "react-icons/lia";
import { BsCupHot } from "react-icons/bs";
import { IoCameraOutline, IoGameControllerOutline } from "react-icons/io5";
import { GiClothes, GiTrojanHorse, GiSpookyHouse } from "react-icons/gi";
import { Link } from 'react-router-dom';

const Component = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 40px 0px 40px !important',
    overflowX: 'overlay',
    [theme.breakpoints.down(960)]: {
        margin: '75px 0px 0px 0px !important'

    }
}))

const Container = styled(Box)`
    padding: 12px 8px;
    text-align: center;
    min-width: 90px;
`

const Text = styled(Typography)(({theme}) => ({
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'inherit',
    [theme.breakpoints.down(960)]: {
        fontSize: 11
    }
}));

const iconComponents = {
    'GoGear': GoGear,
    'MdSportsScore': MdSportsScore,
    "LuPartyPopper": LuPartyPopper,
    "LiaBicycleSolid": LiaBicycleSolid,
    "IoCameraOutline": IoCameraOutline,
    "BsCupHot": BsCupHot,
    "IoGameControllerOutline": IoGameControllerOutline,
    "GiClothes": GiClothes,
    "LiaToolsSolid": LiaToolsSolid,
    "GiTrojanHorse": GiTrojanHorse,
    "GiSpookyHouse": GiSpookyHouse
};

const NavBar = () => {

    return (
        <Component  >
            {
                navData.map(temp => {
                    const IconComponent = iconComponents[temp.icon.trim()];
                    return (
                        <Link to={`category/${temp.text.toLowerCase()}`} style={{textDecoration : "none" ,color : "black"}}>
                            <Container >
                                {IconComponent && <IconComponent size={20} />}
                                <Text>{temp.text}</Text>
                            </Container>
                        </Link>
                    )
                })
            }
            
        </Component>
    )
}

export default NavBar;