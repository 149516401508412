import { Card, Button, Descriptions, Modal, Row, Col, Space, notification, Timeline } from 'antd';
import styled from 'styled-components';
import { addEllipsis } from '../../utils/util';
import { MdKeyboardDoubleArrowDown, MdKeyboardDoubleArrowUp } from "react-icons/md";
import { BASE_URL } from '../../constants';
import { useAuth0 } from '@auth0/auth0-react';
import { message } from 'antd';
import { CancelOrder, updateOrderField } from '../../redux/actions/myOrdersActions';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../AxiosInsstance';
import dayjs from 'dayjs';
import FeedbackForm from './FeedbackForm';
import { useState } from 'react';

const StyledCard = styled(Card)`
  margin-bottom: 16px;
  border-radius: 12px;
  background: #fff;
  transition: all 0.3s ease;
  border: 1px solid #eee;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(173, 30, 36, 0.12);
  }

  .ant-card-body {
    padding: 20px;
  }
`;

const ProductImage = styled.div`
  position: relative;
  width: 140px;
  height: 140px;
  border-radius: 12px;
  overflow: hidden;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(173, 30, 36, 0) 0%, rgba(173, 30, 36, 0.1) 100%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

const StatusBadge = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px 12px;
  border-radius: 20px;
  background: ${props => props.status === 'picked' ? '#AD1E24' : '#4CAF50'};
  color: white;
  font-size: 12px;
  font-weight: 500;
  z-index: 1;
`;

const InfoSection = styled.div`
  padding: 16px;
  background: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const PriceTag = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #AD1E24;
  margin-bottom: 8px;
`;

const ActionButton = styled(Button)`
  border-radius: 8px;
  height: 40px;
  font-weight: 500;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(173, 30, 36, 0.2);
  }
`;

const DisabledCard = styled(StyledCard)`
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  filter: ${props => props.disabled ? 'grayscale(1)' : 'none'};
`;

const OrderItem = ({ item, filter }) => {
    const [feedback, setFeedback] = useState({ rating: 0, comment: '' });
    const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [existingReview, setExistingReview] = useState(null);
    const { user } = useAuth0();
    const dispatch = useDispatch();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const handleFeedbackClick = () => {
        setFeedbackModalVisible(true);
    };

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    const items = [
        { key: '0', label: 'Title', children: item?.title?.shortTitle ? addEllipsis(item.title.shortTitle, 40) : 'N/A', span: 2 },
        { key: '4', label: 'Price', children: item?.price ? `₹${item.price * 24}` : 'N/A' },
        { key: '1', label: 'Location', children: item?.location || 'N/A' },
        { key: '2', label: 'Address', children: item?.address || 'N/A' },
        { key: '3', label: 'Quantity', children: item?.quantity || 'N/A' },
        { key: '5', label: 'Review Status', children: item?.status || 'N/A' },
        { key: '6', label: 'Product Status', children: item?.liststatus || 'N/A' },
        { key: '7', label: 'Product by', children: item?.productby || 'N/A' }
    ];

    const rentItems = (() => {
        const startDate = new Date(item.rentdate.startDateTime);
        const endDate = new Date(item.rentdate.endDateTime);
        const duration = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

        return [
            { key: '0-0', label: 'Rent Period', children: `${duration} days` },
            { key: '0-1', label: 'Start Date', children: formatDate(item.rentdate.startDateTime) },
            { key: '0-2', label: 'End Date', children: formatDate(item.rentdate.endDateTime) },
        ];
    })();

    const handlePickedUpClick = async () => {
        const status = filter === "host" ? "returned" : "picked";
        dispatch(updateOrderField(item.session_id, 'pickup_status', status));
    };

    const navigateToGoogleMaps = () => {
        const googleMapsUrl = `https://www.google.com/maps?q=${item?.latitude},${item?.longitute}`;
        window.open(googleMapsUrl, '_blank');
    };

    const handleCancelItem = () => {
        dispatch(CancelOrder(item.session_id, user.email));
    }

    const handleFeedbackSubmit = async () => {
        try {
            const url = existingReview
                ? `${BASE_URL}/update-review/${existingReview._id}`
                : `${BASE_URL}/add-review`;

            const method = existingReview ? 'put' : 'post';

            const response = await axiosInstance[method](url, {
                productId: item.id,
                userEmail: user.email,
                rating: feedback.rating,
                comment: feedback.comment
            });

            console.log('Feedback submitted successfully:', response.data);

            // Close the modal and reset the feedback state
            setFeedbackModalVisible(false);
            setFeedback({ rating: 0, comment: '' });
            setExistingReview(response.data);

            message.success('Feedback submitted successfully');
            notification.success({
                message: 'Success',
                description: 'Feedback submitted successfully',
                placement: 'topRight',
            });

        } catch (error) {
            console.error('Error submitting feedback:', error);
            notification.error({
                message: 'Error',
                description: `Failed to submit feedback: ${error.message}`,
                placement: 'topRight',
            });
        }
    };

    const isItemReturned = item.pickup_status === "returned";
    const isCardDisabled = item.cancel === true;

    return (
        <DisabledCard disabled={isCardDisabled}>
            <Row gutter={[24, 24]}>
                <Col xs={24} sm={8} md={6}>
                    <ProductImage>
                        <StatusBadge status={isCardDisabled ? 'cancelled' : item.pickup_status}>
                            {isCardDisabled ? 'CANCELLED' : item.pickup_status?.toUpperCase()}
                        </StatusBadge>
                        <img src={item.url?.[0]} alt={item.title?.shortTitle} />
                    </ProductImage>
                </Col>

                <Col xs={24} sm={16} md={12}>
                    <PriceTag>₹{item?.price * 24}</PriceTag>
                    <h3>{item?.title?.shortTitle}</h3>
                    
                    <InfoSection>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <strong>Location:</strong> {item?.location}
                            </Col>
                            <Col xs={24} sm={12}>
                                <strong>Status:</strong> {item?.liststatus}
                            </Col>
                            <Col xs={24} sm={12}>
                                <strong>Quantity:</strong> {item?.quantity}
                            </Col>
                            <Col xs={24} sm={12}>
                                <strong>By:</strong> {item?.productby}
                            </Col>
                        </Row>
                    </InfoSection>

                    {isExpanded && (
                        <InfoSection>
                            <h4 style={{ marginBottom: 16 }}>Rental Period</h4>
                            <Timeline
                                items={[
                                    {
                                        color: '#AD1E24',
                                        children: `Start: ${formatDate(item.rentdate.startDateTime)}`
                                    },
                                    {
                                        color: '#AD1E24',
                                        children: `End: ${formatDate(item.rentdate.endDateTime)}`
                                    }
                                ]}
                            />
                        </InfoSection>
                    )}
                </Col>

                <Col xs={24} md={6}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <ActionButton
                            type="primary"
                            block
                            onClick={handlePickedUpClick}
                            disabled={isCardDisabled || isItemReturned || !(
                                (filter === "host" && item.pickup_status === "picked") ||
                                (filter === "myrents" && (item.pickup_status === "returned" || item.pickup_status === "pending"))
                            )}
                            style={{ backgroundColor: '#AD1E24', borderColor: '#AD1E24' }}
                        >
                            {filter === "host" ? "Return" : "Picked Up"}
                        </ActionButton>

                        <Row gutter={[8, 8]}>
                            <Col span={12}>
                                <ActionButton
                                    block
                                    onClick={handleExpandClick}
                                    icon={isExpanded ? <MdKeyboardDoubleArrowUp /> : <MdKeyboardDoubleArrowDown />}
                                    disabled={isCardDisabled}
                                >
                                    {isExpanded ? 'Less' : 'More'}
                                </ActionButton>
                            </Col>
                            <Col span={12}>
                                <ActionButton 
                                    block 
                                    onClick={navigateToGoogleMaps}
                                    disabled={isCardDisabled || isItemReturned}
                                >
                                    Navigate
                                </ActionButton>
                            </Col>
                        </Row>

                        {filter !== "host" && (
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <ActionButton 
                                        block 
                                        onClick={handleFeedbackClick}
                                        disabled={isCardDisabled || isItemReturned}
                                    >
                                        Feedback
                                    </ActionButton>
                                </Col>
                                <Col span={12}>
                                    <ActionButton
                                        block
                                        danger
                                        disabled={isCardDisabled || isItemReturned || isButtonDisabled || item.pickup_status === "picked"}
                                        onClick={handleCancelItem}
                                    >
                                        Cancel
                                    </ActionButton>
                                </Col>
                            </Row>
                        )}
                    </Space>
                </Col>
            </Row>

            <Modal
                title={existingReview ? "Update Product Feedback" : "Product Feedback"}
                open={feedbackModalVisible}
                onOk={handleFeedbackSubmit}
                onCancel={() => setFeedbackModalVisible(false)}
                okButtonProps={{ style: { backgroundColor: '#AD1E24', borderColor: '#AD1E24' } }}
            >
                <FeedbackForm 
                    item={item}
                    feedback={feedback}
                    setFeedback={setFeedback}
                />
            </Modal>
        </DisabledCard>
    );
};

export default OrderItem;