import { notification } from 'antd';
import * as actionTypes from '../constants/userConstants';
import axiosInstance from '../../AxiosInsstance';

export const getUsers = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_USERS_REQUEST });
    const { data } = await axiosInstance.get('/users');

    dispatch({
      type: actionTypes.GET_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_USERS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const updateProfile = (formData) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATE_PROFILE_REQUEST });

    const response = await axiosInstance.post('/signup', formData);

    dispatch({
      type: actionTypes.UPDATE_PROFILE_SUCCESS,
      payload: response.data,
    });

    notification.success({
      message: 'Success',
      description: 'Profile updated successfully',
      placement: 'topRight',
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_PROFILE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });

    notification.error({
      message: 'Error',
      description: `Error: ${error.message}`,
      placement: 'topRight',
    });
  }
};

export const fetchUserByEmail = (email) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_USER_REQUEST });

    const { data } = await axiosInstance.get(`/user/${email}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    dispatch({
      type: actionTypes.FETCH_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_USER_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const changeUserStatus = (id, status) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CHANGE_USER_STATUS_REQUEST });

    const { data } = await axiosInstance.put(`/api/users/${id}`, { status });

    dispatch({
      type: actionTypes.CHANGE_USER_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.CHANGE_USER_STATUS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};