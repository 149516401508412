import React from 'react'
import axiosInstance from '../../AxiosInsstance';
import { Button } from 'antd';

const RFA = () => {
    const withdrawal = async () => {
        try {
            const response = await axiosInstance.post(`/create-vendor`, {
                email: 'vendor@example.com',
                name: 'Jane Doe',
                phone: '4161234567', // Canadian phone number format
                address: {
                  line1: '1234 Main Street',
                  city: 'Toronto',
                  state: 'ON', // Canadian province code
                  postal_code: 'M5H 2N2', // Canadian postal code
                  country: 'CA', // Change to 'CA' for Canada
                },
                bankAccount: {
                  transitNumber: '12345', // Canadian transit number
                  institutionNumber: '001', // Canadian institution number
                  accountNumber: '000123456789', // Canadian bank account number
                  routingNumber: '11000-000', // Concatenate institutionNumber and transitNumber
                },
              });
            console.log(response.data);
        } catch (error) {
            console.error('Error during withdrawal:', error);
        }
    };

    return (
        <div>
            <Button onClick={withdrawal} type="primary" style={{ marginTop: '10px' }}>
                Withdraw
            </Button>
        </div>
    );
};

export default RFA;