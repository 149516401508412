import React, { useEffect } from 'react';
import { Box, CircularProgress, Divider, Skeleton, styled } from '@mui/material';
import NavBar from './Home/NarBar';
import Banner from './Home/Banner';
import Slide from './Home/Slide';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts as listProducts, resetProducts } from '../redux/actions/productActions';
import { fetchCartItems } from '../redux/actions/cartActions';
import { useAuth0 } from '@auth0/auth0-react';
import Cateogories from './Home/Cateogories';
import BannerSlide from './Home/BannerSilde';
import HomeScreenProducts from './Home/HomeScreenProducts';
import { notification, Spin } from 'antd';
import { ClockLoader } from 'react-spinners';
import { Hidden } from '@material-ui/core';

const Component = styled(Box)`
    padding: 20px 10px;
`;

const StyledDiv = styled('div')(({ theme }) => ({
    padding: '0px 40px',


    [theme.breakpoints.down('sm')]: {
        padding: '0px 5px',
    },
}));


const Home = () => {
    const getProducts = useSelector(state => state.getProducts);
    const { products, loading, error } = getProducts;
    const dispatch = useDispatch();
    const { user, getAccessTokenSilently } = useAuth0();


    // useEffect(() => {
    //     const fetchProductsAndCartItems = async () => {

    //         try {
    //             await dispatch(listProducts());
    //         } catch (error) {
    //             notification.error({
    //                 message: 'Error',
    //                 description: `Failed to list products: ${error.message}`,
    //                 placement: 'topRight',
    //             });
    //         }

    //         if (user?.email) {


    //             try {
    //                 const token = await getAccessTokenSilently();
    //                 await dispatch(fetchCartItems(user.email,token));
    //             } catch (error) {
    //                 console.log('Error while fetching cart items');
    //                 console.log(error);
    //                 notification.error({
    //                     message: 'Error',
    //                     description: `Failed to fetch cart items: ${error.message}`,
    //                     placement: 'topRight',
    //                 });
    //             }
    //         }
    //     };

    //     fetchProductsAndCartItems();
    // }, [user]);

    let electronicsProducts = [];
    let outdoorGearProducts = [];
    let sportsProducts = [];
    let partyEventsProducts = [];

    if (products && products.length > 0) {
        electronicsProducts = products.filter(item => item.category === "Electronics" && item.status !== 'review' && item.status !== 'Rejected' && item.liststatus === 'published');
        outdoorGearProducts = products.filter(item => item.category === "Outdoor Gear" && item.status !== 'review' && item.status !== 'Rejected' && item.liststatus === 'published');
        sportsProducts = products.filter(item => item.category === "Sports" && item.status !== 'review' && item.status !== 'Rejected' && item.liststatus === 'published');
        partyEventsProducts = products.filter(item => item.category === "Party & Events" && item.status !== 'review' && item.status !== 'Rejected' && item.liststatus === 'published');
    }

    return (
        <StyledDiv>
            <NavBar />
            <Divider />
            <Component>

                <Spin spinning={loading}>
                    <Banner />
                </Spin>

                <Hidden mdDown>
                    <Cateogories />
                </Hidden>
                <BannerSlide />

                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
                        <ClockLoader  speedMultiplier={3} size={75} />
                        <h4>Loading...</h4>
                    </div>
                ) : (
                    <>
                        {electronicsProducts.length > 0 && (
                            <Slide
                                data={electronicsProducts}
                                title='Electronics'
                                timer={false}
                                multi={true}
                            />
                        )}
                        {outdoorGearProducts.length > 0 && (
                            <Slide
                                data={outdoorGearProducts}
                                title='Outdoor Gear'
                                timer={false}
                                multi={true}
                            />
                        )}
                        {sportsProducts.length > 0 && (
                            <Slide
                                data={sportsProducts}
                                title='Sports'
                                timer={false}
                                multi={true}
                            />
                        )}
                        {partyEventsProducts.length > 0 && (
                            <Slide
                                data={partyEventsProducts}
                                title='Party & Events'
                                timer={false}
                                multi={true}
                            />
                        )}
                    </>
                )}
                <HomeScreenProducts products={products} />
            </Component>
        </StyledDiv >
    );
};

export default Home;