import { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, Box, IconButton, Drawer, List, styled, useMediaQuery, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Logo from "../../assets/rentinstant.png";
import CustomButtons from './CustomButtons';
import Search from './Search';
import Profile from './Profile';
import { useAuth0 } from '@auth0/auth0-react';
import { FaMapLocationDot } from "react-icons/fa6";
import { Button, Modal, Select as AntdSelect, Tour } from 'antd';
import { navData } from '../../constant/data';
import { useDispatch, useSelector } from 'react-redux';
import { setCategoryState, setLocationState } from '../../redux/actions/productActions';
import { GiWallet } from "react-icons/gi";
import AddLocation from '../../Map/AddLocation';
import { SearchOutlined } from '@ant-design/icons';
import DateModal from '../../reusables/DateRangePicker';
import { FaMapMarkerAlt } from "react-icons/fa";
import { CgCalendarDates } from "react-icons/cg";
import { setShowVerifyUserLogin } from '../../redux/verifyUserLogin';

const { Option } = AntdSelect;

const StyledHeader = styled(AppBar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1001,
    height: '70px', // Default height

    [theme.breakpoints.down(960)]: {
        height: '120px', // Height for medium screens and below
    },
}));

const Component = styled(Link)(({ theme }) => ({
    lineHeight: 0,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down(960)]: {
        display: 'none',
    }
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down(960)]: {
        display: 'block'
    }
}));

const CustomButtonWrapper = styled('span')(({ theme }) => ({
    [theme.breakpoints.down(960)]: {
        display: 'none'
    }
}));

const WalletContainer = styled('div')({
    borderRadius: '10px',
    textAlign: 'center',
    display: 'flex',
    color: 'black',
    border: '1px solid rgba(173, 30, 36, 0.2)',
    padding: '6px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    boxShadow: '0 4px 8px rgba(173, 30, 36, 0.3)',// Added shadow
    backgroundColor: 'white', 
    height : "40px"// Optional: Add background color for better contrast
});

const Header = () => {
    const [open, setOpen] = useState(false);
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const isMdUp = useMediaQuery((theme) => theme.breakpoints.up(960));
    const isSmDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [category, setCategory] = useState('');
    const ref1 = useRef(null);
    const mainUser = useSelector(state => state.user.user);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const defaultlocation = useSelector(state => state.location.location);
    const {user} = useSelector(state => state.user);
    const [location, setLocation] = useState({
        lng: 0,
        lat: 0,
        town: '',
        country: '',
        state: '',
        address: '',
    });
    const [openDateRangePickerModal, setOpenDateRangePickerModal] = useState(false);
    const startDate = useSelector((state) => state.daterangepicker.startDate);
    const endDate = useSelector((state) => state.daterangepicker.endDate);

    const isvisible = useSelector((state) => state.verifyUserLogin.showVerifyUserLogin);

    const steps = [
        {
            title: 'Welcome!',
            description: 'Please Edit the Profile to continue',
            target: () => ref1.current,
            placement: 'bottom',
        },
    ];

    const showLocationModal = () => {
        setIsModalVisible(true);
    };

    const handleLocationOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        dispatch(setLocationState({
            lng: 0,
            lat: 0,
            town: '',
            country: '',
            state: '',
            address: '',
        }));

        setIsModalVisible(false);
    };

    useEffect(() => {
        dispatch(setLocationState(location));
    }, [location]);

    return (
        <StyledHeader position="fixed" style={{ backgroundColor: "white" }} >
            <Toolbar style={{ minHeight: isMdUp ? 40 : 120, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "65px" }}>
                    <MenuButton onClick={() => setOpen(true)}>
                        <Menu />
                    </MenuButton>

                    <Drawer open={open} onClose={() => setOpen(false)}>
                        <Box style={{ width: 250 }} onClick={() => setOpen(false)}>
                            <List>
                                <listItem button>
                                    <CustomButtons />
                                </listItem>
                            </List>
                        </Box>
                    </Drawer>

                    <Component to='/'>
                        <img src={Logo} style={{ width: 60, marginLeft: "20px" }} />
                    </Component>
               
                    <Search />

                    {isMdUp && (
                        <>
                            <Button onClick={() => setOpenDateRangePickerModal(true)} style={{ marginRight: "20px" }}>
                                {startDate && endDate
                                    ? `${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`
                                    : 'Select Date'}
                            </Button>
                            <Button type="default" onClick={showLocationModal}>
                                <SearchOutlined />{!defaultlocation?.town ? "Location" : defaultlocation?.town}
                            </Button>
                        </>
                    )}

                    {isSmDown && (
                        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                            {isAuthenticated ? (
                                <div style={{ cursor: "pointer" }} ref={ref1}>
                                    <Profile />
                                </div>
                            ) : (
                                <Button
                                    ref={ref1}
                                    onClick={() => loginWithRedirect()}
                                    style={{ backgroundColor: "#AD1E24", color: "white" }}
                                >
                                    Login
                                </Button>
                            )}
                        </div>
                    )}

                    <CustomButtonWrapper>
                        <CustomButtons ref1={ref1} />
                    </CustomButtonWrapper>

                    <Link to="/mywallet" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <WalletContainer>
                            <div style={{ fontSize: "16px", fontWeight: "600", display: "flex", justifyContent: "center", alignItems: "center", gap: "8px", height: "30px" }}>
                                <GiWallet size={18} />
                            </div>
                            <div style={{ fontSize: "16px", fontWeight: "600" }}>
                                {mainUser?.wallet ? (parseFloat(mainUser.wallet) / 100).toFixed(2) : '0.00'}
                            </div>
                        </WalletContainer>
                    </Link>
                </div>

                <div style={{ display: "flex", height: "40px", gap: "5px" }}>
                    {!isMdUp && (
                        <>
                            <AntdSelect
                                id="category-select"
                                value={category}
                                onChange={(event) => {
                                    setCategory(event);
                                    dispatch(setCategoryState(event.toLowerCase()));
                                }}
                                variant="outlined"
                                style={{
                                    fontFamily: "sans-serif",
                                    width: "100%"
                                }}
                            >
                                <Option value="">All Categories</Option>
                                {navData.map((item, index) => (
                                    <Option key={index} value={item.text}>
                                        {item.text}
                                    </Option>
                                ))}
                            </AntdSelect>
                            <Button type="default" onClick={showLocationModal}>
                                <FaMapMarkerAlt size={18} />{!defaultlocation?.town ? "Loc" : defaultlocation?.town.substring(0, 3)}
                            </Button>
                            <Button type="default" onClick={() => setOpenDateRangePickerModal(true)}>
                                <CgCalendarDates size={18} /> {startDate && endDate
                                    ? `${new Date(startDate).toLocaleDateString('en-US', { month: '2-digit', day: 'numeric' })} - ${new Date(endDate).toLocaleDateString('en-US', { month: '2-digit', day: 'numeric' })}`
                                    : 'Date'}
                            </Button>
                        </>
                    )}
                </div>

                <Modal
                    title="Location"
                    open={isModalVisible}
                    onOk={handleLocationOk}
                    onCancel={handleCancel}
                    centered
                    footer={[
                        <Button key="reset" onClick={handleCancel}>
                            Reset
                        </Button>,
                        <Button key="ok" type="primary" onClick={handleLocationOk}>
                            OK
                        </Button>,
                    ]}
                >
                    <AddLocation setLocation={setLocation} location={location} />
                </Modal>

                <DateModal isOpen={openDateRangePickerModal} onClose={() => setOpenDateRangePickerModal(false)} />

                <Tour
                    steps={steps}
                    current={0}
                    open={isvisible}
                    onClose={() => dispatch(setShowVerifyUserLogin(false))}
                    onPrev={() => dispatch(setShowVerifyUserLogin(false))}
                    onNext={() => dispatch(setShowVerifyUserLogin(false))}
                    duration={3000}
                />
            </Toolbar>
        </StyledHeader>
    );
}

export default Header;