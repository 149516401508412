import * as actionTypes from '../constants/userConstants';

export const getUsersReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_REQUEST:
      return { loading: true, users: [] };
    case actionTypes.GET_USERS_SUCCESS:
      return { users: action.payload, loading: false };
    case actionTypes.GET_USERS_FAIL:
      return { error: action.payload, loading: false };
    default:
      return state;
  }
};

export const FetchUserData = (state = { user: {} , loading : false, status : false }, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_REQUEST:
      return { loading: true, user: {} , status : false };
    case actionTypes.FETCH_USER_SUCCESS:
      return { user: action.payload, loading: false , status : true };
    case actionTypes.FETCH_USER_FAIL:
      return { error: action.payload, loading: false , status : false };
    case actionTypes.UPDATE_PROFILE_REQUEST:
      return { ...state, loading: true , status : false};
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return { user: action.payload, loading: false , status : true };
    case actionTypes.UPDATE_PROFILE_FAIL:
      return { ...state, error: action.payload, loading: false , status : false };
    default:
      return state;
  }
};

export const changeUserStatusReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case actionTypes.CHANGE_USER_STATUS_REQUEST:
        return { ...state, loading: true };
      case actionTypes.CHANGE_USER_STATUS_SUCCESS:
        return { ...state, loading: false, user: action.payload };
      case actionTypes.CHANGE_USER_STATUS_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
