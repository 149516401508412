import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import store from './redux/store';
import config from './config.json';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Define your theme colors
const theme = createTheme({
  palette: {
    primary: {
      main: '#0A2472',
    },
    secondary: {
      main: '#B2FEFF',
    },
    website: {
      main_back: '#0A2472',
    },
  },
});



const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={config.domain}
    clientId={config.clientId}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    authorizationParams={
      {
        audience: "https://api.rentinstant.ca/",
        scope: "openid profile email"
      }
    }
  >
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {/* <React.StrictMode> */}
          <App />
        {/* </React.StrictMode> */}
      </ThemeProvider>
    </Provider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();