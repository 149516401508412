import React, { useEffect, useState } from 'react';
import { Box, Typography, Link as MuiLink, styled, Skeleton, useMediaQuery } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as colors from '../Color/color';
import Profile from './Profile';
import { IoCartOutline } from "react-icons/io5";
import Logo from '../../assets/rentinstant.png';
import { TbShoppingBagPlus } from "react-icons/tb";
import CircularProgress from '@mui/material/CircularProgress';
import { RiParentLine } from "react-icons/ri";
import { Button, Badge, notification, Tour } from 'antd';
import { TbCoinFilled } from "react-icons/tb";
import { setShowVerifyUserLogin } from '../../redux/verifyUserLogin';

const Container = styled(Link)(({ theme }) => ({
    display: 'inline-flex',
    textDecoration: 'none',
    gap: '5px',
    alignItems: 'center',
    '&:hover': {
        color: colors.secondary_theme_color,
    },
    [theme.breakpoints.down(960)]: {
        display: 'inline-flex',
        flexDirection: 'row',
        color: "#2874f0",
        minHeight: '40px',
        justifyContent: 'start',
        alignItems: 'center',
        backgroundColor: "#f5f5f5",
        width: '210px',
        gap: '5px',
        '&:hover': {
            color: colors.secondary_theme_color,
        },
    }
}));

const Wrapper = styled(Box)(({ theme }) => ({
    margin: '0 3% 0 auto',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    '& > *': {
        textDecoration: 'none',
        color: '#FFFFFF',
        fontSize: 16,
        '&:hover': {
            color: colors.secondary_theme_color,
            cursor: 'pointer'
        },
        [theme.breakpoints.down(960)]: {
            color: '#2874f0',
            padding: '10px 10px 10px 10px',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '250px',
        }
    },
    [theme.breakpoints.down(960)]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: "250px",
        alignItems: "start"
    }
}));

const ComponentLOGO = styled(Link)(({ theme }) => ({
    display: 'none',
    '&:hover': {
        color: colors.secondary_theme_color,
    },
    [theme.breakpoints.down(960)]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const SubHeading = styled(Typography)`
    font-size: 10px;
    font-style: italic;
    color: ${colors.text_color};
`;

const CartTypography = styled(Typography)(({ theme }) => ({
    color: "black",
    textDecoration: 'none',
    '&:hover': {
        color: colors.secondary_theme_color,
    },
    [theme.breakpoints.down(960)]: {
        color: "#2874f0",
    },

}));

const ProfileHolder = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: "pointer",
    padding: '10px',
    [theme.breakpoints.down(960)]: {
        display: "none"
    },
    '&:hover': { // Add this block
        transform: 'scale(1.1)',
    },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    fontSize: 16,
    margin: "0px 10px",
    alignItems: 'center',
    [theme.breakpoints.down(960)]: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '250px',
        margin: '0px 0px',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },

    }
}));

const CustomButtons = ({ ref1 }) => {
    const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
    const cartDetails = useSelector(state => state.cart);
    const { cartItems } = cartDetails;
    const dispatch = useDispatch();
    const { user , status } = useSelector(state => state.user);
    
    const isvisible = useSelector((state) => state.verifyUserLogin.showVerifyUserLogin);
    const isSmDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleLogin = (event) => {
        if (!isAuthenticated) {
            event.preventDefault();
            notification.info({
                message: 'Authentication Required',
                description: 'Please login to continue',
                placement: 'topRight',
            });
            loginWithRedirect();
        }
    };

    useEffect(() => {
        if(status)
        {
            dispatch(setShowVerifyUserLogin(false));
        }
        else{
            dispatch(setShowVerifyUserLogin(true));
        }

    }, [isAuthenticated, user]);

    return (
        <Wrapper>
            <ComponentLOGO to='/'>
                <img src={Logo} style={{ width: 40 }} alt="Rental App Logo" />
                <Box component="span" style={{ display: 'flex' }}>
                    <SubHeading>
                        Rental&nbsp;
                        <Box component="span" style={{ color: `${colors.secondary_theme_color}` }}>
                            App
                        </Box>
                    </SubHeading>
                </Box>
            </ComponentLOGO>
            <ButtonWrapper>
                <Container to='/addproduct' onClick={handleLogin}>
                    <Badge count={0} style={{ backgroundColor: "#AD1E24", color: 'white' }}>
                        <TbShoppingBagPlus color='black' size={20} />
                    </Badge>
                    <CartTypography>Add</CartTypography>
                </Container>

                <Container to='/cart' onClick={handleLogin}>
                    <IoCartOutline color='black' size={20} />
                    <CartTypography>List</CartTypography>
                </Container>

                <Container to='/myOrders' onClick={handleLogin}>
                    <Badge count={0} style={{ backgroundColor: "#AD1E24", color: 'white' }}>
                        <RiParentLine color='black' size={20} />
                    </Badge>
                    <CartTypography>Rents</CartTypography>
                </Container>
            </ButtonWrapper>

            {!isSmDown && (
                <ProfileHolder>
                    {isLoading ? (
                        <>
                            <CircularProgress style={{ position: "absolute" }} />
                            <Skeleton variant="circular" width={40} height={40} />
                        </>
                    ) : isAuthenticated ? (
                        <div style={{ cursor: "pointer" }} ref={ref1}>
                            <Profile />
                        </div>
                    ) : (
                        <Button ref={ref1} onClick={() => loginWithRedirect()} style={{ backgroundColor: "#AD1E24", color: "white" }}>
                            Login
                        </Button>
                    )}
                </ProfileHolder>
            )}
        </Wrapper>
    );
}

export default CustomButtons;