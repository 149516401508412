import React from "react";
import {
  Button,
  Table,
  Typography,
  Image,
  Row,
  Col,
  Card,
  Divider,
} from "antd";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { SlCalender } from "react-icons/sl";
import dayjs from "dayjs";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import { BiDetail } from "react-icons/bi";
import { CgTag } from "react-icons/cg";
import { IoMdPricetag } from "react-icons/io";
import { IoIosTime } from "react-icons/io";
import { IoMdCube } from "react-icons/io";
import { BASE_URL } from "../../constants";

const { Title, Text } = Typography;

const Page2 = ({
  product,
  startDateTime,
  endDateTime,
  insurance,
  calculatePriceWithCommission,
  totalPriceofItem,
  insurancePrice,
}) => {
  const calculateCommission = () => {
    const totalPrice = totalPriceofItem;
    const commission = totalPrice * 0.05;
    return commission.toFixed(2);
  };

  const productDetailsData = [
    {
      icon: <BiDetail style={{ color: "#AD1E24", fontSize: "24px" }} />,
      label: "Item Name",
      value: `${product?.title?.shortTitle}`,
    },
    {
      icon: <CgTag style={{ color: "#AD1E24", fontSize: "24px" }} />,
      label: "Category",
      value: product?.category?.toUpperCase(),
    },
    {
      icon: <MdLocationOn style={{ color: "#AD1E24", fontSize: "24px" }} />,
      label: "Location",
      value: product?.location,
    },
    {
      icon: <AiOutlineHome style={{ color: "#AD1E24", fontSize: "24px" }} />,
      label: "Address",
      value: product?.address,
    },
    {
      icon: <IoMdCube style={{ color: "#AD1E24", fontSize: "24px" }} />,
      label: "Quantity",
      value: product?.quantity,
    },
   
  ];

  const priceDetailsData = [
    {
      icon: <FaRegCalendarAlt />,
      label: "Start Date/Time",
      value: dayjs(startDateTime).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      icon: <FaRegCalendarAlt />,
      label: "End Date/Time",
      value: dayjs(endDateTime).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      icon: <IoIosTime />,
      label: "How many hours",
      value: `${Math.abs(endDateTime - startDateTime) / 36e5} hours`,
    },
    {
      icon: <IoMdPricetag />,
      label: "Price",
      value: `$${product?.price * 24} / day`,
    },
    {
      icon: <IoMdPricetag />,
      label: "Net Price",
      value: `$${totalPriceofItem}`,
    },
    {
      icon: <IoMdPricetag />,
      label: "Commission",
      value: `$${calculateCommission()}`,
    },
    {
      icon: <IoMdPricetag />,
      label: "Total Price",
      value: `$${calculatePriceWithCommission()}`,
    },
  ];

  if (insurance) {
    priceDetailsData.push({
      icon: <IoMdPricetag />,
      label: "Insurance",
      value: `$${insurancePrice}`,
    });
  }

  return (
    <div style={{ padding: "20px", display : "flex" }}>
      {/* Product Details Section */}
      <Card style={
        {
            width : "50%"
        }
      }>
        <Col xs={24}>
          <div
            className="product-details"
            style={{
              padding: "25px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
            }}
          >
            <Image
              width={250}
              height={250}
              src={product?.url?.[0]}
              alt={product?.title?.shortTitle}
              style={{ borderRadius: "10px", objectFit: "cover" }}
            />
            <div>
              <Title level={5}>Price Details</Title>
              <Table
              size="small"
                pagination={false}
                dataSource={productDetailsData}
                columns={[
                  {
                    title: "Detail",
                    dataIndex: "label",
                    render: (text, record) => (
                      <div style={{display : "flex" , alignItems : "center" , gap : "10px"}}>
                        {record.icon} {text}
                      </div>
                    ),
                  },
                  { title: "Value", dataIndex: "value" },
                ]}
              />
            </div>
          </div>
        </Col>
      </Card>

      {/* Price Details Section */}
      <Card
        style={{
          width: "50%",
          borderRadius: "12px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
        }}
        bodyStyle={{ padding: "24px" }}
      >
        {/* Vertical Heading and Subheading */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#AD1E24",
            marginBottom: "16px",
          }}
        >
          <Title level={3} style={{ fontWeight: "bold", margin: 0 }}>
            Product Details
          </Title>
          <Text
            style={{ fontSize: "18px", fontWeight: "500", color: "#AD1E24" }}
          >
            Specifications
          </Text>
        </div>

        <Divider style={{ borderColor: "#AD1E24" }} />
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Title level={4} style={{ marginBottom: "15px" }}>
              Price Details
            </Title>
            <Row gutter={[16, 16]}>
              {priceDetailsData.map((item, index) => (
                <Col xs={24} sm={12} md={8} key={index}>
                  <Card
                    hoverable
                    style={{
                      borderRadius: "12px",
                      backgroundColor: "#fdf6f6",
                      border: "1px solid #AD1E24",
                      transition: "transform 0.3s, box-shadow 0.3s",
                    }}
                    bodyStyle={{ padding: "16px" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.02)";
                      e.currentTarget.style.boxShadow =
                        "0px 6px 20px rgba(0, 0, 0, 0.15)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.boxShadow =
                        "0px 4px 12px rgba(0,0,0,0.1)";
                    }}
                  >
                    <Text
                      strong
                      style={{
                        fontSize: "16px",
                        color: "#AD1E24",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      {item.icon} {item.label}:
                    </Text>
                    <Text style={{ fontSize: "14px", color: "#555" }}>
                      <strong>{item.value}</strong>
                    </Text>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Page2;
