import { CANCEL_ORDER_FAIL, CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS, FETCH_ORDERS_FAIL, FETCH_ORDERS_REQUEST, FETCH_ORDERS_SUCCESS, UPDATE_ORDER_FIELD } from '../constants/myOrdersContant';
import axiosInstance from '../../AxiosInsstance';

export const fetchOrders = (email) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ORDERS_REQUEST });

        const { data } = await axiosInstance.get(`/myorders?email=${email}`);

        dispatch({
            type: FETCH_ORDERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_ORDERS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

export const updateOrderField = (session_id, key, value) => async (dispatch) => {
    try {
        const response = await axiosInstance.put('/transactionupdatevalues', { session_id, key, value });
        dispatch({
            type: UPDATE_ORDER_FIELD,
            payload: { session_id, key, value }
        });
    } catch (error) {
        console.error('Error updating order field:', error);
    }
};

export const CancelOrder = (session_id,email) => async (dispatch) => {
    try {
        dispatch({ type: CANCEL_ORDER_REQUEST });

        const response = await axiosInstance.post('/cancel-rent', { session_id });

        dispatch({ type: CANCEL_ORDER_SUCCESS, payload: response.data });
        dispatch(fetchOrders(email));
    } catch (error) {
        dispatch({
            type: CANCEL_ORDER_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};