import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    searchModalDrawer: false
}

const searchModalDrawer = createSlice({
  name: "searchModalDrawer",
  initialState,
  reducers: {
    setSearchModalDrawer: (state, action) => {
      state.searchModalDrawer = action.payload;
    }
  }
}
);



export const { setSearchModalDrawer } = searchModalDrawer.actions;

export default searchModalDrawer.reducer;